<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="edit">
                                    <button title="back page" (click)="backToLeaveProfile()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3>Leave Adjustments Detail</h3>
                            </div>
                        </div>
                        <div class="col-sm-6 d-flex justify-content-end">
                            <div class="add-btn">
                                <button type="button" (click)="createOpenModal()" title="Create" data-bs-toggle="modal" data-bs-target="#createLeaveAdjustment" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular
                        style="height: calc(100vh - 290px) !important;" 
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        paginationPageSize=10>
                    </ag-grid-angular>
                </div>
            </section>
        </section>
    </article>
</main>

<!-- Disabled Date Modal -->
<div class="modal fade" id="createLeaveAdjustment" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form [formGroup] = "leaveAdjustmentsForm">
                <div class="modal-header">
                    <h5 class="modal-title">Leave Adjustments</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-sm-12">  
                            <div class="input">
                                <div class="input-item">
                                    <input list="userList" formControlName="userId"/>
                                    <datalist id="userList">
                                        <option [value]="item" *ngFor="let item of userDropdownList"></option>
                                    </datalist>
                                    <label class="floating-label-special">Employee Name*</label>
                                    <i class="ri-user-line"></i>
                                </div>
                                <mat-error *ngIf="f.userId.touched && f.userId.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="f.userId.errors.required">User Name is required</mat-error>
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-12">  
                            <div class="input">
                                <div class="input-item">
                                    <input formControlName="createdByUserId"/>
                                    <label class="floating-label-special">Requester User</label>
                                    <i class="ri-user-line"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="input">
                                <div class="input-item">
                                    <select formControlName="leaveYear">
                                        <option value="" selected disabled>Select Leave Year</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                    </select>
                                    <label class="floating-label-special">Leave Year*</label>
                                    <i class="ri-user-line"></i>
                                </div>
                                <mat-error *ngIf="f.leaveYear.touched && f.leaveYear.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="f.leaveYear.errors.required">Leave Year is required</mat-error>
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="input">
                                <div class="input-item">
                                    <select formControlName="leaveType">
                                        <option value="" disabled selected>Select Leave Type</option>
                                        <option value="Earned Leave">Earned Leave</option>
                                        <option value="Casual Leave">Casual Leave</option>
                                        <option value="Compensatory leave">Compensatory Leave</option>
                                        <option value="Paternity Leave">Paternity Leave</option>
                                        <option value="Maternity Leave">Maternity Leave</option>
                                    </select>
                                    <label class="floating-label-special">Leave Type*</label>
                                    <i class="ri-user-line"></i>
                                </div>
                                <mat-error *ngIf="f.leaveType.touched && f.leaveType.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="f.leaveType.errors.required">Leave Type is required</mat-error>
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="input">
                                <div class="input-item">
                                    <input type="text" formControlName="daysAdjusted"/>
                                    <label class="floating-label-special">Days Adjusted*</label>
                                    <i class="ri-user-line"></i>
                                </div>
                                <mat-error *ngIf="f.daysAdjusted.touched && f.daysAdjusted.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="f.daysAdjusted.errors.required">Days to Adjust is required</mat-error>
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="input">
                                <div class="input-item">
                                    <input type="date" formControlName="effectiveDate"/>
                                    <label class="floating-label-special">Effective Date*</label>
                                    <i class="ri-user-line"></i>
                                </div>
                                <mat-error *ngIf="f.effectiveDate.touched && f.effectiveDate.invalid" class="alert alert-danger">
                                    <mat-error *ngIf="f.effectiveDate.errors.required">Effective Date is required</mat-error>
                                </mat-error>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="input">
                                <div class="input-item">
                                    <textarea name="" id="" cols="12" rows="2" formControlName="reason"></textarea>
                                    <label class="floating-label-special">Reason</label>
                                    <i class="ri-file-list-line"></i>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModal" type="button" class="btn btn-primary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" (click)="createLeaveAdjustment()">Add</button>
                </div>
            </form>
        </div>
    </div>
</div>