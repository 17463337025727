<main class="main-dashboard">
    <article class="main-canvas">
        <section class="canvas-container">
            <section class="dashboard-items">
                <form [formGroup] = "leaveUserForm">
                <div class="canvas-item">
                    <div class="canvas-header row justify-content-between align-items-center">
                        <div class="title col-sm-6">
                            <div class="d-flex align-items-center">
                                <div class="edit">
                                    <button title="back page" (click)="backToMyLeave()">
                                    <span><i class="ri-arrow-left-line"></i></span>
                                    </button>
                                </div>
                                <h3 class="px-3">Leave Detail </h3>
                            </div>   
                        </div>
                        <div *ngIf="this.isLeaveApplicationDisabled" class="col-sm-6 d-flex justify-content-end">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button title="Edit" type="button" data-bs-toggle="modal" data-bs-target="#disabledDateModal">
                                  <span><i class="ri-pencil-line"></i></span>
                                </button>
                              </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" title="Delete" data-bs-toggle="modal" data-bs-target="#disabledDateModal">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div class="add-btn">
                                <button type="button" title="Create" data-bs-toggle="modal" data-bs-target="#disabledDateModal" class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                            <div class="col-sm-3" style="margin-left: 10px;">
                                <div class="input mb-0 no-min-width">
                                    <select (change)="handleChange($event)">
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025" selected>2025</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!this.isLeaveApplicationDisabled" class="col-sm-6 d-flex justify-content-end">
                            <div class="edit" *ngIf="selectedCheckboxData.length === 1">
                                <button title="Edit" type="button" (click)="updateLeaveApp()">
                                  <span><i class="ri-pencil-line"></i></span>
                                </button>
                              </div>
                            <div class="delete" *ngIf="selectedCheckboxData.length > 0">
                                <button type="button" title="Delete" data-bs-toggle="modal" data-bs-target="#deleteLeaveModal" (click)="deleteOpenModal()">
                                    <span><i class="ri-delete-bin-5-line"></i></span>
                                </button>
                            </div>
                            <div class=" add-btn">
                                <button type="button" title="Create" (click)="openLeaveApp()"  class="btn btn-icon">
                                    <i class="ri-add-line"></i>
                                </button>
                            </div>
                            <div class="col-sm-3" style="margin-left: 10px;">
                                <div class="input mb-0 no-min-width">
                                    <select (change)="handleChange($event)">
                                        <option value="2022">2022</option>
                                        <option value="2023">2023</option>
                                        <option value="2024">2024</option>
                                        <option value="2025" selected>2025</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-1 align-items-center">
                        <div class="col-sm-12 col-md-12 col-lg-8" *ngIf="this.isAdmin">
                            <div class="row my-2 justify-content-center" >
                                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                                    <div class="input">
                                        <div class="input-item">
                                            <input list="taskOwnerDatalist" formControlName="employeeId" placeholder="Select Name" value="" />
                                            <datalist id="taskOwnerDatalist">
                                                <option [value]="item" *ngFor="let item of userDropdownList"></option>
                                            </datalist>
                                            <label class="floating-label">Employee Name</label>
                                            <i class="ri-user-line"></i>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3 text-center">
                                    <button type="button" class="btn" (click)=getLeaveApplicationByUserId()>
                                        <div>Submit</div>
                                    </button>
                                </div>
                                <!-- <div class="col-sm-3">
                                    <button type="button" data-bs-toggle="modal" data-bs-target="#createVisitor">
                                        <div>Edit Leave</div>
                                    </button>
                                </div> -->
                                
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-4 col-xl-4">
                            <div class="row grey-boxes">
                                <div class="col-sm-12 col-md-4 col-lg-4">
                                    <div class="box">
                                        <p class="table-box-heading">Earned Leave</p>
                                        <i class="ri-calendar-check-line" style="color:purple"></i>
                                        <p class="text-left">Available: {{earnleave}}</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4 col-lg-4">
                                    <div class="box">
                                        <p class="table-box-heading">Casual Leave</p>
                                        <i class="ri-calendar-check-line" style="color:purple"></i>
                                        <p class="text-left">Available: {{casualleave}}</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-4 col-lg-4">
                                    <div class="box">
                                        <p class="table-box-heading">Compensatory off</p>
                                        <i class="ri-calendar-check-line" style="color:green"></i>
                                        <p class="text-left">Available: {{compensatoryleave}}</p>
                                    </div>
                                </div>
                                <!-- <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="this.isMaternityLeave">
                                    <div class="box2 row">
                                        <p class="table-box-heading">Maternity Leave</p>
                                        <i class="ri-calendar-check-line" style="color:rgb(0, 107, 128); margin:0 10px;"></i>
                                        <p class="text-left">Leaves Remaining: {{maternityLeave}}</p>
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="this.isPaternityLeave">
                                    <div class="box2">
                                        <p class="table-box-heading">Paternity Leave</p>
                                        <i class="ri-calendar-check-line" style="color:rgb(0, 107, 128); margin:0 10px;"></i>
                                        <p class="text-left">Leaves Remaining: {{paternityLeave}}</p>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <ag-grid-angular
                        style="height: calc(100vh - 290px) !important;" 
                        class="ag-theme-material"
                        (gridReady)="onGridReady($event)" 
                        [rowData]="rowData" 
                        [columnDefs]="columnDefs"
                        pagination="true" 
                        [defaultColDef]="defaultColDef"
                        [suppressRowClickSelection]="true"
                        [rowSelection]="rowSelection"
                        (selectionChanged)="onSelectionChanged($event)"
                        paginationPageSize=10>
                    </ag-grid-angular>
                </div>
            </form>
            </section>
        </section>
    </article>
</main>


<!-- Delete an item -->
<div class="modal fade" id="deleteLeaveModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Are you sure want to delete?</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{deleteConfirmationMsg}}?</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModalDeleteLeave" type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                    <button type="button" class="btn btn-primary" *ngIf="selectedCheckboxData.length > 0" (click)="deleteleaveAppRates()">Yes Delete</button>
                </div>
            </form>
        </div>
    </div>
</div>


<!-- Disabled Date Modal -->
<div class="modal fade" id="disabledDateModal" tabindex="-1">
    <div class="modal-dialog">
        <div class="modal-content pl-5 pr-5">
            <form action="">
                <div class="modal-header">
                    <h5 class="modal-title">Alert...!!!</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                </div>
                <div class="modal-body">
                    <div class="text-center">
                        <p>{{disabledDateMsg}}</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button id="closeModal" type="button" class="btn btn-primary" data-bs-dismiss="modal">Ok</button>
                </div>
            </form>
        </div>
    </div>
</div>