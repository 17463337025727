import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import 'rxjs/add/operator/filter';
import { HrService } from 'src/app/_services/hr-management/hr.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from '../../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';

@Component({
  selector: 'app-leave-adjustments-page',
  templateUrl: './leave-adjustments.component.html',
  styleUrls: ['./leave-adjustments.component.scss'],
})
export class LeaveAdjustmentsComponent implements OnInit {

    form: FormGroup;
    leaveAdjustmentsForm;
    rowData: any;
    tenantId: string;
    public columnDefs: ColDef[];
    modeAction: number = 1; //1 - create // 2 - update
    defaultColDef: any;
    gridLength = 0;
    sampleGridFrameworkComponents: any;
    // gridApi and columnApi
    private api: GridApi;
    private columnApi: ColumnApi;
    userDropdownList= [];
    currentUser: any;
    getUserName: any;

    constructor(
        private storageService: LocalStorageService,
        private HrService: HrService,
        private formBuilder: FormBuilder,
        private router: Router,
        private allocationService: AllocationService,
    ) {
        this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
        };
        this.columnDefs = this.createColumnDefs();

        this.sampleGridFrameworkComponents = {
            actionRowRendererComponent: ActionItemsGridRowRendererComponent,
        };

        this.form = this.formBuilder.group({
        file: ['', Validators.required],
        });

        this.leaveAdjustmentsForm = this.formBuilder.group({
            id: new FormControl(''),
            userId: new FormControl('', Validators.required),
            userName: new FormControl(''),
            createdByUserId: new FormControl({value: '', disabled: true}),
            createdByUserName: new FormControl({value: '', disabled: true}),
            leaveYear: new FormControl('', Validators.required),
            leaveType: new FormControl('', Validators.required),
            daysAdjusted: new FormControl('', Validators.required),
            effectiveDate: new FormControl('', Validators.required),
            implemented: new FormControl(''),
            reason: new FormControl('')
        });
    }

    ngOnInit(): void {
        this.tenantId = this.storageService.get('tenantId');
        this.currentUser = JSON.parse(this.storageService.get('currentUser'));
        this.getLeaveAdjustmentsReport();
        this.getUserList();
    }

    // getter for easy access to form fields
    get f() {
        return this.leaveAdjustmentsForm.controls;
    }

    getUserList() {
        this.userDropdownList = [];
        this.allocationService.getUserDetails().subscribe((response) => {
            this.getUserName = response;
            for (let item of response) {
                this.userDropdownList.push(`${item.id} . ${item.firstName} ${item.lastName}`);
            }
        });
    }

    getLeaveAdjustmentsReport() {
        this.HrService.getLeaveAdjustmentsReport().subscribe((response: any) => {
            this.rowData = response;
        });
    }

    createOpenModal() {
        this.modeAction = 1;
        this.leaveAdjustmentsForm.reset();
        this.leaveAdjustmentsForm.patchValue({
            id: '',
            userId: '',
            leaveYear: '',
            leaveType: '',
            daysAdjusted: '',
            effectiveDate: '',
            reason: ''
        });
        this.leaveAdjustmentsForm.value.createdByUserId = this.currentUser.id + ' . ' + this.currentUser.firstName + ' ' + this.currentUser.lastName;
        this.leaveAdjustmentsForm.patchValue(this.leaveAdjustmentsForm.value);
      }

    createLeaveAdjustment(){
        let userId = parseInt(this.leaveAdjustmentsForm.get('userId').value.toString().split(" . ")[0]);
        let createdByUserId = parseInt(this.leaveAdjustmentsForm.get('createdByUserId').value.toString().split(" . ")[0]);
        let leaveData = {
            userId: userId,
            userName: this.leaveAdjustmentsForm.get('userId').value.split(' . ')[1],
            createdByUserId: createdByUserId,
            createdByUserName: this.leaveAdjustmentsForm.get('createdByUserId').value.split(' . ')[1],
            daysAdjusted: this.leaveAdjustmentsForm.get('daysAdjusted').value,
            effectiveDate: this.leaveAdjustmentsForm.get('effectiveDate').value,
            leaveType: this.leaveAdjustmentsForm.get('leaveType').value,
            leaveYear: this.leaveAdjustmentsForm.get('leaveYear').value,
            reason: this.leaveAdjustmentsForm.get('reason').value,
          };
          this.HrService.createLeaveAdjustmentsReport(userId, createdByUserId, leaveData).subscribe((response: any) => {
            this.getLeaveAdjustmentsReport();
            $('#closeModal').trigger('click');
          });

    }

    // one grid initialisation, grap the APIs and auto resize the columns to fit the available space
    onGridReady(params): void {
        this.api = params.api;
        this.columnApi = params.columnApi;
        this.api.sizeColumnsToFit();
    }

    // create column definitions
    private createColumnDefs() {
        return [
            {
                headerName: 'Employee Code',
                field: 'localId',
                filter: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: 'Employee Name',
                valueGetter: function(params) {
                    return params.data.userId + " . " + params.data.userName;
                  },
                filter: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: 'Requester Name',
                field: 'createdByUserName',
                filter: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: 'Leave Year',
                field: 'leaveYear',
                filter: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: 'Leave Type',
                field: 'leaveType',
                // valueGetter: function(params){
                //     if(params.data.leaveType === 'Earned Leave') {
                //         let leaveType = 'Casual Leave'
                //         return leaveType;
                //     } else {
                //         return params.data.leaveType;
                //     }
                // },
                filter: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: 'Days Adjusted',
                field: 'daysAdjusted',
                filter: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: 'Effective Date',
                field: 'effectiveDate',
                filter: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: 'Implemented',
                field: 'implemented',
                filter: true,
                editable: false,
                sortable: true,
            },
            {
                headerName: 'Reason',
                field: 'reason',
                filter: true,
                editable: false,
                sortable: true,
            }, 
        ];
    }

    backToLeaveProfile(){
        this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-profile']);
      }
}