<div class="card">
	<form [formGroup]="payslipForm">
	<div class="card-header p-3 row justify-content-between" style="background-color: #fff;">
		<div class="col-sm-12 col-md-4 col-lg-4 col-xl-2 mb-2" *ngIf="this.isAdmin">
			<div class="input mb-0 no-min-width">
			  <div class="input-item">
				<input
				  list="taskOwnerDatalist"
				  formControlName="employeeId"
				  
				  value=""
				/>
				<datalist id="taskOwnerDatalist">
				  <option
					[value]="item"
					*ngFor="let item of userDropdownList"
				  ></option>
				</datalist>
				 <label class="loating-label floating-label-special">Employee Name</label> 
				<i class="ri-user-line"></i>
			  </div>
			</div>
		</div>
        <div class="col-sm-12 col-md-4 col-lg-4 col-xl-2 mb-2">
            <div class="input mb-0 no-min-width">
                <div class="input-item">
					<select formControlName="payslipMonth">
						<!-- <option value="" disabled selected>Select Month</option> -->
						<option value="January">January</option>
						<option value="February">February</option>
						<option value="March">March</option>
						<option value="April">April</option>
						<option value="May">May</option>
						<option value="June">June</option>
						<option value="July">July</option>
						<option value="August">August</option>
						<option value="September">September</option>
						<option value="October">October</option>
						<option value="November">November</option>
						<option value="December">December</option>
					  </select>
					  <label class="loating-label floating-label-special">Select Month</label> 
					  <i class="ri-calendar-line"></i>
                </div>
            </div>
        </div>
		<div class="col-sm-12 col-md-4 col-lg-4 col-xl-2 mb-2">
            <div class="input mb-0 no-min-width">
                <div class="input-item">
					<select formControlName="payslipYear">
						<!-- <option value="" disabled selected>Select Year</option> -->
						<option value="2021">2021</option>
						<option value="2022">2022</option>
						<option value="2023">2023</option>
						<option value="2024">2024</option>
						<option value="2025">2025</option>
						<option value="2026">2026</option>
					  </select>
					  <label class="loating-label floating-label-special">Select Year</label> 
					  <i class="ri-calendar-line"></i>
                </div>
            </div>
        </div>
		<div class="col-sm-12 col-md-4 col-lg-4 col-xl-2 mb-2">
            <div class="input mb-0 no-min-width">
                <div class="input-item">
					<select formControlName="payslipFinancialYear">
						<!-- <option value="" disabled selected>Select Financial Year</option> -->
						<option value="2021-2022">2021-2022</option>
						<option value="2022-2023">2022-2023</option>
						<option value="2023-2024">2023-2024</option>
						<option value="2024-2025">2024-2025</option>
						<option value="2025-2026">2025-2026</option>
					  </select>
					  <label class="loating-label floating-label-special">Select Financial Year</label> 
					  <i class="ri-calendar-line"></i>
                </div>
            </div>
        </div>
		<div class="col-sm-12 col-md-4 col-lg-4 col-xl-2 mb-2 text-end text-right">
			<button class="btn" (click)="payslipTillMonth();getPayslip()">View</button>
		</div>
		<!-- <div class="col-sm-12" style="display: flex; align-items: center;">
			<div [hidden]="hidePrintView" class="col-sm-12 col-md-4 col-lg-4 col-xl-2 mb-2 text-end text-right">
				<button (click)="downloadPDF()"></button>
				<button class="btn" (click)="downloadPDF()"><i class="ri-download-line"></i> Send Over Email</button>
			</div>
			<div [hidden]="hidePrintView" class="col-sm-12 col-md-4 col-lg-4 col-xl-2 mb-2 text-end text-right">
				<button class="btn" id="print"><i class="ri-printer-line"></i> Print</button>
			</div>
		</div> -->
	</div>
	</form>

	<div id="registration">
		<div class="card-body" [hidden]="hidePrintView">
			<div class="p-3 mb-3">
				<div class="canvas-header row justify-content-between align-items-center">
					<div class="col-sm-4">
						<h3 class="h5">Payslip</h3>
					</div>
				</div>
				
				<div class="invoice-table">
					<div *ngIf="this.isTenantCustomised" class="row align-items-center border">
						<div class="col-sm-6">
							<img src="/assets/third-party-logo/imtecnology-logo.png" style="max-width: 180px">
						</div>
						<div class="col-sm-6 text-end text-right">
							<img src="" style="max-width: 120px">
							<h4 class="m-0">iM Technologies Pvt.Ltd.</h4>
						</div>
					</div>
					<div *ngIf="!this.isTenantCustomised" class="row align-items-center border">
						<div class="col-sm-6">
							<img src="/assets/Pragamana_icon (3).jpg" style="max-width: 180px">
						</div>
						<div class="col-sm-6 text-end text-right">
							<img src="" style="max-width: 120px">
							<h4 class="m-0">Pragamana Pvt.Ltd.</h4>
						</div>
					</div>
					<table class="table border">
						<tr>
							<td>Name:</td>
							<td class="border-right">{{ this.employeeName }}</td>
							<td>Location:</td>
							<td class="border-right">{{ currentUserId.location }}</td>
							<td>Pay slip No:</td>
							<td class="border-right">{{ this.payslipNo }}</td>
							<td>Designation:</td>
							<td class="border-right">{{ this.currentDesignation }}</td>
						</tr>
						<tr>
							<td>Emp ID:</td>
							<td class="border-right">{{ this.localId }}</td>
							<td>Bank:</td>
							<td class="border-right">{{ this.bankName }}</td>
							<td>UAN No:</td>
							<td class="border-right">{{ this.uanNo }}</td>
							<td>Pay Period:</td>
							<td class="border-right">{{ this.month }} {{ this.year }}</td>
						</tr>
						<tr>
							<td>Group:</td>
							<td class="border-right">{{ this.permanentOrContractor }}</td>
							<td>A/C No:</td>
							<td class="border-right">{{ this.bankAccontNo }}</td>
							<td>Pan:</td>
							<td class="border-right">{{ this.panNo }}</td>
							<td>Working day:</td>
							<td class="border-right">{{ this.noOfTotalDays }}</td>
						</tr>
						<tr>
							<td>DOJ:</td>
							<td class="border-right">{{ this.joiningDate }}</td>
							<td>IFSC Code:</td>
							<td class="border-right">{{ this.ifscCode }}</td>
							<td>ESIC No:</td>
							<td class="border-right">{{this.esicNo}}</td>
							<td>Payable Days:</td>
							<td class="border-right">{{ this.noOfPayableDays }}</td>
						</tr>
						<tr>
							<td colspan="4" class="text-center border" style="font-weight: bold">{{this.month}}-{{this.year}}</td>
							<!-- <td colspan="4" class="text-center border" style="font-weight: bold">April To {{this.month}}</td> -->
						    <td colspan="4" class="text-center border" style="font-weight: bold">Year To Date</td>
						</tr>
						<tr>
							<td colspan="2" class="text-center border-bottom" style="font-weight: bold">Earning</td>
							<td colspan="2" class="text-center border-bottom border-right border-left" style="font-weight: bold">Deduction</td>
							<td colspan="2" class="text-center border-bottom border-right" style="font-weight: bold">Earning</td>
							<td colspan="2" class="text-center border-bottom" style="font-weight: bold">Deduction</td>
						</tr>
						<tr>
							<td class="border-left border-rigth"><p *ngFor="let payable of payables">{{ payable.componentName }}</p></td>
							<td ><p *ngFor="let payable of payables">{{ payable.componentValue }}</p></td>
							<td class="border-left border-rigth"><p *ngFor="let deduction of updatedDeductions">{{ deduction.componentName }}</p></td>
							<td ><p *ngFor="let deduction of updatedDeductions">{{ deduction.componentValue }}</p></td>
							<td class="border-left border-rigth"><p *ngFor="let payabless of payabless">{{ payabless.componentName }}</p></td>
							<td ><p *ngFor="let payabless of payabless">{{ payabless.componentValue }}</p></td>
							<td class="border-left border-rigth"><p *ngFor="let deductionss of updatedDeductionss">{{ deductionss.componentName }}</p></td>
							<td ><p *ngFor="let deduction of updatedDeductionss">{{ deduction.componentValue }}</p></td>
						</tr>

						<tr class="last-child">
							<td class="border-top bg-grey">Total Payments</td>
							<td class="border-top border-right bg-grey" >₹ {{ this.totalPayable }} </td>
							<td class="border-top bg-grey">Total Deduction</td>
							<td class="border-top border-right bg-grey"> ₹ {{ this.totalDeduction }} </td>
							<td class="border-top bg-grey"></td>
							<td class="border-top bg-grey border-right"></td>
							<td class="border-top bg-grey"></td>
							<td class="border-top bg-grey"></td>
						</tr>
						
						<tr>
							<td colspan="2" class="border-bottom border-right bg-grey border-top" style="font-weight: bold">Net Salary:</td>
							<td colspan="6" class="border-top bg-grey">₹ {{ this.netPayable }} </td>
						</tr>
						<tr>
							<td colspan="2" class="border-bottom border-right bg-grey" style="font-weight: bold">Net Salary in word:</td>
							<td colspan="6" class="border-top bg-grey">₹ {{ this.netPayableInWords }} </td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</div>
</div>