import { Component, OnInit } from '@angular/core';
import { Roles } from "../../../../_models/roles";
import * as _ from 'lodash';
import { UserService } from "../../../../_services/user/user.service";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataService } from 'src/app/_services/data-share/data.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { ActivatedRoute, Router } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { AvatarService } from 'src/app/_services/avatar/avatar.service';
import { CostService } from 'src/app/_services/cost/cost.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { MatSnackBar } from '@angular/material/snack-bar'; 
import * as XLSX from 'xlsx';
import { ColDef, GridApi, ColumnApi, ExcelExportParams , ExcelStyle} from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
  jsonData: any;
  usersForm: FormGroup;
  public activeIndex: boolean = false;
  tenantId: string;
  // to access Roles enum and populate dropdown
  keys = Object.keys;
  rolesEnum = Roles;
  dataSource: any;
  rolesArray: Array<any> = [];
  selectedRowId: any;
  selectedRow: any;
  currentTab: number = 1;
  userImage: string;
  selectedFile: File;
  retrievedImage: any;
  marriagedate: any;
  costDropdownList = [];
  userDropdownList = [];
  isArchived : any;
  getUserName: any;
  displayError = '';
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  form: FormGroup;
  private api: GridApi;
  isTenantCustomised: boolean;
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update;
  // gridApi and columnApi  
  private columnApi: ColumnApi;  
   defaultColDef:any;
   rowSelection:any;
   sampleGridFrameworkComponents: any;
  gridLength = 0;
  selectedCheckboxData = []
  deleteConfirmationMsg = 'Are you sure you want to delete'

  constructor(
    private userService: UserService,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private router: Router,
    private storageService: LocalStorageService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private avatar: AvatarService,
    private costService: CostService,
    private allocationService: AllocationService,
    private snackBar: MatSnackBar) {

      this.defaultColDef = {
        flex: 1,
        minWidth: 100,
        resizable: true,
      };
      this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();  
  
    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };

    
      this.form = this.formBuilder.group({
        file: ['', Validators.required]
      });

      this.usersForm = this.formBuilder.group({
        id: new FormControl(''),
        version: new FormControl(''),
        userId: new FormControl('', [ Validators.required, Validators.pattern('^[a-zA-Z0-9 ]*$') ] ),
        password: new FormControl('', [Validators.pattern(/^(?=\D*\d)(?=[^a-z]*[a-z])(?=[^A-Z]*[A-Z]).{8,30}$/)]),
        firstName: new FormControl('', [ Validators.required, Validators.pattern('^[a-zA-Z ]*$') ]),
        lastName: new FormControl('', [ Validators.required, Validators.pattern('^[a-zA-Z ]*$') ]),
        displayName: new FormControl('', Validators.required),
        department: new FormControl('', Validators.required),
        emailId: new FormControl('', [ Validators.required, Validators.pattern(this.emailPattern), Validators.email ]),
        phoneNo: new FormControl('', [ Validators.required, Validators.pattern('^[0-9 ]*$') ]),
        location: new FormControl('', Validators.required),
        roles: new FormControl(''),
        image: new FormControl(''),
        branch: new FormControl(''),
        groups: new FormControl(''),
        costCenter: new FormControl(''),
        localId: new FormControl('', Validators.pattern('#+[0-9]*$')),
        employeeCategory: new FormControl(''),
        level: new FormControl(''),
        joinDate: new FormControl(''),
        quitDate: new FormControl(''),
        permanentOrContractor: new FormControl(''),
        localOrExpat: new FormControl(''),
        level2: new FormControl(''),
        resourceType: new FormControl(''),
        onboardStatus: new FormControl(''),
        supervisorId: new FormControl(''),
        dashboardIndex: new FormControl(''),
        birthDate: new FormControl('', Validators.required),
        hrId: new FormControl(''),
        hrName: new FormControl(''),
        supervisonName: new FormControl(''),
        adhaarNo: new FormControl(''),
        bankName: new FormControl(''),
        bankAccountNo: new FormControl(''),
        panNo: new FormControl(''),
        uinNo: new FormControl(''),
        archived: new FormControl(''),
        admin: new FormControl(''),
        joinWeek: new FormControl(''),
        quitWeek: new FormControl(''),
        marriageDate: new FormControl(''),
        marriageStatus: new FormControl(''),
        userType: new FormControl(''),
        alternateEmailId: new FormControl('', [Validators.pattern(this.emailPattern), Validators.email]),
        country: new FormControl(''),
        currentDesignation: new FormControl(''),
        previousDesignation: new FormControl(''),
        offeredDesignation: new FormControl(''),
        deputedAt: new FormControl(''),
        bankIfscCode: new FormControl(''),
        address: new FormControl(''),
        gender: new FormControl(''),
        careerStartDate: new FormControl(''),
        middleName: new FormControl(''),
        managerId: new FormControl(''),
        managerName: new FormControl(''),
        addedToBioMetrics: new FormControl(''),
        totalExperience: new FormControl(''),
        currentExperience: new FormControl(''),
        emergencyContactNo: new FormControl(''),
        bloodGroup: new FormControl(''),
      });

      if (this.isTenantCustomised) {
        this.usersForm.get('localId').setValidators([Validators.required]);
      }
  }

  // getter for easy access to form fields
  get f() {
    return this.usersForm.controls;
  }

  ngOnInit() {
    this.tenantId = this.storageService.get('tenantId');
    this.getUsers();
    this.getCostCenterLevelRates();
    this.getUserList();
    const customisedTenants = ['imtechnologies']
    this.isTenantCustomised = customisedTenants.includes(this.tenantId);
    let keys = Object.keys(this.rolesEnum);
    let roleArray = Object.values(this.rolesEnum);
    for (let index = 0; index < roleArray.length; index++) {
      this.rolesArray.push(
        {
          lable: roleArray[index],
          value: keys[index]
        }
      )
    }
  }

  getUsers() {
    this.userService.getUserDetailsList().subscribe((response: any) => {
      let resp = response;
      if(response) {
        this.userService.getArchivedUserDetailsList().subscribe((response2:any) => {
          this.rowData = [...resp, ...response2];
        });
      }
      else{
        this.rowData = resp;
      }
    });
  }

  showInput(num:number) {
    this.currentTab = num;
  }

  checkmarritalstatus(marritalstatvalue){
    this.marriagedate = marritalstatvalue;
  }

  openCreate() {
    this.resetForm();
    this.retrievedImage = './assets/user-icon-jpg-29.jpg';
    this.selectedFile = null;
  }

  createUsers() {
    let details = {
      userId: this.usersForm.get('userId').value,
      password: this.usersForm.get('password').value,
      firstName: this.usersForm.get('firstName').value,
      lastName: this.usersForm.get('lastName').value,
      displayName: this.usersForm.get('displayName').value,
      department: this.usersForm.get('department').value,
      emailId: this.usersForm.get('emailId').value,
      phoneNo: this.usersForm.get('phoneNo').value,
      location: this.usersForm.get('location').value,
      roles: this.usersForm.get('roles').value,
      image: this.usersForm.get('image').value,
      branch: this.usersForm.get('branch').value,
      groups: this.usersForm.get('groups').value,
      costCenter: this.usersForm.get('costCenter').value,
      localId: this.usersForm.get('localId').value,
      employeeCategory: this.usersForm.get('employeeCategory').value,
      level: this.usersForm.get('level').value,
      joinDate: this.usersForm.get('joinDate').value,
      quitDate: this.usersForm.get('quitDate').value,
      permanentOrContractor: this.usersForm.get('permanentOrContractor').value,
      localOrExpat: this.usersForm.get('localOrExpat').value,
      level2: this.usersForm.get('level2').value,
      resourceType: this.usersForm.get('resourceType').value,
      onboardStatus: this.usersForm.get('onboardStatus').value,     
      dashboardIndex: this.usersForm.get('dashboardIndex').value,
      birthDate: this.usersForm.get('birthDate').value,
      hrId: this.usersForm.get('hrName').value.split(" . ")[0],
      hrName: this.usersForm.get('hrName').value.split(" . ")[1],
      supervisorId: this.usersForm.get('supervisonName').value.split(" . ")[0],
      supervisonName: this.usersForm.get('supervisonName').value.split(" . ")[1],
      adhaarNo: this.usersForm.get('adhaarNo').value,
      bankName: this.usersForm.get('bankName').value,
      bankAccountNo: this.usersForm.get('bankAccountNo').value,
      panNo: this.usersForm.get('panNo').value,
      uinNo: this.usersForm.get('uinNo').value,
      archived: this.usersForm.get('archived').value,
      admin: this.usersForm.get('admin').value,
      joinWeek: this.usersForm.get('joinWeek').value,
      quitWeek: this.usersForm.get('quitWeek').value,
      marriageDate: this.usersForm.get('marriageDate').value,
      marriageStatus: this.usersForm.get('marriageStatus').value,
      userType: this.usersForm.get('userType').value,
      alternateEmailId: this.usersForm.get('alternateEmailId').value,
      country: this.usersForm.get('country').value,
      currentDesignation: this.usersForm.get('currentDesignation').value,
      previousDesignation: this.usersForm.get('previousDesignation').value,
      offeredDesignation: this.usersForm.get('offeredDesignation').value,
      deputedAt: this.usersForm.get('deputedAt').value,
      bankIfscCode: this.usersForm.get('bankIfscCode').value,
      address: this.usersForm.get('address').value,
      gender: this.usersForm.get('gender').value,
      careerStartDate: this.usersForm.get('careerStartDate').value,
      middleName: this.usersForm.get('middleName').value,
      managerId: this.usersForm.get('managerId').value,
      managerName: this.usersForm.get('managerName').value,
      addedToBioMetrics: this.usersForm.get('addedToBioMetrics').value,
      totalExperience: this.usersForm.get('totalExperience').value,
      currentExperience: this.usersForm.get('currentExperience').value,
      emergencyContactNo: this.usersForm.get('emergencyContactNo').value,
      bloodGroup: this.usersForm.get('bloodGroup').value,
    }
    this.userService.createUser(details).subscribe((response: any) => {
      if(this.selectedFile != null && response != null) {
        this.onUpload(response.id);
      }
      setTimeout(() => {
        this.getUsers();
      }, 200);
      $('#closeUserAddModal').trigger("click");
    });
  }

  updateUser(id) {
    if(this.isArchived === false && this.usersForm.get('archived').value === 'true'){
      if(this.usersForm.get('quitDate').value){

        let details = {
          userId: this.usersForm.get('userId').value,
          firstName: this.usersForm.get('firstName').value,
          lastName: this.usersForm.get('lastName').value,
          displayName: this.usersForm.get('displayName').value,
          department: this.usersForm.get('department').value,
          emailId: this.usersForm.get('emailId').value,
          phoneNo: this.usersForm.get('phoneNo').value,
          location: this.usersForm.get('location').value,
          roles: this.usersForm.get('roles').value,
          image: this.usersForm.get('image').value,
          branch: this.usersForm.get('branch').value,
          groups: this.usersForm.get('groups').value,
          costCenter: this.usersForm.get('costCenter').value,
          localId: this.usersForm.get('localId').value,
          employeeCategory: this.usersForm.get('employeeCategory').value,
          level: this.usersForm.get('level').value,
          joinDate: this.usersForm.get('joinDate').value,
          quitDate: this.usersForm.get('quitDate').value,
          permanentOrContractor: this.usersForm.get('permanentOrContractor').value,
          localOrExpat: this.usersForm.get('localOrExpat').value,
          level2: this.usersForm.get('level2').value,
          resourceType: this.usersForm.get('resourceType').value,
          onboardStatus: this.usersForm.get('onboardStatus').value,
          dashboardIndex: this.usersForm.get('dashboardIndex').value,
          birthDate: this.usersForm.get('birthDate').value,
          hrId: this.usersForm.get('hrName').value.split(" . ")[0],
          hrName: this.usersForm.get('hrName').value.split(" . ")[1],
          supervisorId: this.usersForm.get('supervisonName').value.split(" . ")[0],
          supervisonName: this.usersForm.get('supervisonName').value.split(" . ")[1],
          adhaarNo: this.usersForm.get('adhaarNo').value,
          bankName: this.usersForm.get('bankName').value,
          bankAccountNo: this.usersForm.get('bankAccountNo').value,
          panNo: this.usersForm.get('panNo').value,
          uinNo: this.usersForm.get('uinNo').value,
          archived: this.usersForm.get('archived').value,
          admin: this.usersForm.get('admin').value,
          joinWeek: this.usersForm.get('joinWeek').value,
          quitWeek: this.usersForm.get('quitWeek').value,
          marriageDate: this.usersForm.get('marriageDate').value,
          marriageStatus: this.usersForm.get('marriageStatus').value,
          userType: this.usersForm.get('userType').value,
          alternateEmailId: this.usersForm.get('alternateEmailId').value,
          country: this.usersForm.get('country').value,
          currentDesignation: this.usersForm.get('currentDesignation').value,
          previousDesignation: this.usersForm.get('previousDesignation').value,
          offeredDesignation: this.usersForm.get('offeredDesignation').value,
          deputedAt: this.usersForm.get('deputedAt').value,
          bankIfscCode: this.usersForm.get('bankIfscCode').value,
          address: this.usersForm.get('address').value,
          gender: this.usersForm.get('gender').value,
          careerStartDate: this.usersForm.get('careerStartDate').value,
          middleName: this.usersForm.get('middleName').value,
          managerId: this.usersForm.get('managerId').value,
          managerName: this.usersForm.get('managerName').value,
          addedToBioMetrics: this.usersForm.get('addedToBioMetrics').value,
          totalExperience: this.usersForm.get('totalExperience').value,
          currentExperience: this.usersForm.get('currentExperience').value,
          emergencyContactNo: this.usersForm.get('emergencyContactNo').value,
          version: this.usersForm.get('version').value,
          bloodGroup: this.usersForm.get('bloodGroup').value,
        }
    
        this.userService.updateUser(id, details).subscribe(response => {
          this.getUsers();
          $('#closeUserEditModal').trigger("click");
        });

      }
      else{
        this.displayError = "Please Enter quit date"
        this.snackBar.open("Please Enter quit date", 'Close', { duration: 5000 });
        document.getElementById("display-error").classList.remove("d-none");
        document.getElementById("display-error").classList.add("text-danger");
      }
    }
    else{
      let details = {
        userId: this.usersForm.get('userId').value,
        firstName: this.usersForm.get('firstName').value,
        lastName: this.usersForm.get('lastName').value,
        displayName: this.usersForm.get('displayName').value,
        department: this.usersForm.get('department').value,
        emailId: this.usersForm.get('emailId').value,
        phoneNo: this.usersForm.get('phoneNo').value,
        location: this.usersForm.get('location').value,
        roles: this.usersForm.get('roles').value,
        image: this.usersForm.get('image').value,
        branch: this.usersForm.get('branch').value,
        groups: this.usersForm.get('groups').value,
        costCenter: this.usersForm.get('costCenter').value,
        localId: this.usersForm.get('localId').value,
        employeeCategory: this.usersForm.get('employeeCategory').value,
        level: this.usersForm.get('level').value,
        joinDate: this.usersForm.get('joinDate').value,
        quitDate: this.usersForm.get('quitDate').value,
        permanentOrContractor: this.usersForm.get('permanentOrContractor').value,
        localOrExpat: this.usersForm.get('localOrExpat').value,
        level2: this.usersForm.get('level2').value,
        resourceType: this.usersForm.get('resourceType').value,
        onboardStatus: this.usersForm.get('onboardStatus').value,
        dashboardIndex: this.usersForm.get('dashboardIndex').value,
        birthDate: this.usersForm.get('birthDate').value,
        hrId: this.usersForm.get('hrName').value.split(" . ")[0],
        hrName: this.usersForm.get('hrName').value.split(" . ")[1],
        supervisorId: this.usersForm.get('supervisonName').value.split(" . ")[0],
        supervisonName: this.usersForm.get('supervisonName').value.split(" . ")[1],
        adhaarNo: this.usersForm.get('adhaarNo').value,
        bankName: this.usersForm.get('bankName').value,
        bankAccountNo: this.usersForm.get('bankAccountNo').value,
        panNo: this.usersForm.get('panNo').value,
        uinNo: this.usersForm.get('uinNo').value,
        archived: this.usersForm.get('archived').value,
        admin: this.usersForm.get('admin').value,
        joinWeek: this.usersForm.get('joinWeek').value,
        quitWeek: this.usersForm.get('quitWeek').value,
        marriageDate: this.usersForm.get('marriageDate').value,
        marriageStatus: this.usersForm.get('marriageStatus').value,
        userType: this.usersForm.get('userType').value,
        alternateEmailId: this.usersForm.get('alternateEmailId').value,
        country: this.usersForm.get('country').value,
        currentDesignation: this.usersForm.get('currentDesignation').value,
        previousDesignation: this.usersForm.get('previousDesignation').value,
        offeredDesignation: this.usersForm.get('offeredDesignation').value,
        deputedAt: this.usersForm.get('deputedAt').value,
        bankIfscCode: this.usersForm.get('bankIfscCode').value,
        address: this.usersForm.get('address').value,
        gender: this.usersForm.get('gender').value,
        careerStartDate: this.usersForm.get('careerStartDate').value,
        middleName: this.usersForm.get('middleName').value,
        managerId: this.usersForm.get('managerId').value,
        managerName: this.usersForm.get('managerName').value,
        addedToBioMetrics: this.usersForm.get('addedToBioMetrics').value,
        totalExperience: this.usersForm.get('totalExperience').value,
        currentExperience: this.usersForm.get('currentExperience').value,
        emergencyContactNo: this.usersForm.get('emergencyContactNo').value,
        version: this.usersForm.get('version').value
      }
  
      this.userService.updateUser(id, details).subscribe(response => {
        this.getUsers();
        $('#closeUserEditModal').trigger("click");
      });
    }
  }

  editUser(id) {
    if (id != 0) {
      this.userService.getUser(id).subscribe((data: any) => {
        this.usersForm.patchValue(data);
      });
    }
  }

  // to open edit modal
  editOpenModal() {
    console.log("editdate",this.selectedCheckboxData[0]);
    this.selectedRow = this.selectedCheckboxData[0];
    this.modeAction = 2;
    this.checkmarritalstatus(this.selectedRow.marriageStatus);
    this.usersForm.patchValue(this.selectedCheckboxData[0]);
    this.usersForm.value.hrName = this.selectedCheckboxData[0].hrId + ' . ' + this.selectedCheckboxData[0].hrName;
    this.usersForm.value.supervisonName = this.selectedCheckboxData[0].supervisorId + ' . ' + this.selectedCheckboxData[0].supervisonName;
    this.usersForm.patchValue(this.usersForm.value);
    this.isArchived = this.selectedCheckboxData[0].archived;
    this.getImage();
  }

  keyValueArray(array) {
    let newArray = [];
    for (let index = 0; index < array.length; index++) {
      newArray.push({
        value: array[index]
      })
    }
    return newArray
  }

  toggle(){
    $('.offcanvas').toggleClass('show').css('visibility','visible');
    $('body').css("overflow", "hidden");
    $('body').css("padding-right", "17px");
  }

  resetForm() {
    this.usersForm.reset();
  }

  getImage() {
    let id = this.selectedRow.id;
    this.userService.getUser(id).subscribe((response: any) => {
      if(response.image == null) {
        this.userImage = './assets/user-icon-jpg-29.jpg';
      } else {
        this.userImage = 'data:image/jpeg;base64,' + response.image;
      }
    });
  }

  //Gets called when the user selects an image
  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files[0];
    if (this.selectedFile != null) {
      if(this.selectedFile.size < 2000000){
        // Set a filereader object to asynchronously read the contents of files (or raw data buffers) stored on the user's computer, 
        // using File or Blob objects to specify the file or data to read. 
        let reader = new FileReader();
        // We declare an event of the fileReader class (onload event) and we register an anonimous function that will be executed 
        // when the event is raised. it is "trick" we preapare in order for the onload event to be raised after 
        // the last line of this code will be executed (fileReader.readAsDataURL(imageFile);)
        reader.onloadend = () => {
          this.retrievedImage = reader.result;
        }
        // This line will raise the fileReader.onload event - note we are passing the file object here 
        // as an argument to the function of the event
        reader.readAsDataURL(this.selectedFile);
      }
      else{
        window.alert('The image size should be max upto 2MB');
      }
    } 
    // clear the input
    event.target.value = null;
  }

  //Gets called when the user clicks on submit to upload the image
  onUpload(id) {
    //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();
    uploadImageData.append('imageFile', this.selectedFile, this.selectedFile.name);
    
    //Make a call to the Spring Boot Application to save the image
    this.avatar.uploadImage(id, uploadImageData).subscribe((response: any) => {
    });
  }


  getCostCenterLevelRates() {
    this.costDropdownList = []
    this.costService.getDefaultCostCenterLevelRates().subscribe((response:any) => {
      for (let item of response) {
        this.costDropdownList.push(item.costcenter)
      }
    });
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  public imprtUserOnFileChanged(event){
    this.selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(this.selectedFile);
    fileReader.onload = (event) => {
      let binaryData = event.target.result;
      let workbook = XLSX.read(binaryData, {type: 'binary'});
      workbook.SheetNames.forEach(sheet => {
        const data = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
        let details = JSON.stringify(data);
        alert("Uploading in proress... Press OK and Please Wait...");
        for( let i=0;i<data.length;i++){
          this.userService.createUser(JSON.stringify(data[i])).subscribe(response => {
            // update grid after creating new lead & close the modal
            this.api.applyTransaction({ add: [response] });
          });
        }
      })
      alert("Upload Complete.Thank you for choosing Pragamana.");
      this.api.refreshCells();
    }
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
   onGridReady(params): void {
    this.api = params.api;
    console.log('qqqq', this.api)
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  } 

    // create column definitions  
    private createColumnDefs() {
      return [
      {
        headerName: 'First Name',
        field: 'firstName',
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Last Name',
        field: 'lastName',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Emp Id',
        field: 'localId',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Department',
        field: 'department',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Email Id',
        field: 'emailId',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Phone No',
        field: 'phoneNo',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Deputed At',
        field: 'deputedAt',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Current Designation',
        field: 'currentDesignation',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Archived',
        // field: 'archived',
        valueGetter: function(params){
            if(params.data.archived === true) {
              let archived = 'True'
              return archived;
            } else {
              let archived = 'False'
                return archived;
            }
          },
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Join Date',
        field: 'joinDate',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Quit Date',
        field: 'quitDate',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Roles ',
        field: 'roles',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'SupervisonName ',
        field: 'supervisonName',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Birth Date ',
        field: 'birthDate',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Total Experience ',
        field: 'totalExperience',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Resource Type ',
        field: 'resourceType',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Location ',
        field: 'location',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
      {
        headerName: 'Gender ',
        field: 'gender',
        filter: true,
        editable: false,
        sortable: true,
        hide: true,
      },
    
     ]
    }

    onSelectionChanged(event) {
      this.selectedCheckboxData = []
      let select = event.api.getSelectedNodes();
      for (let item of select) {
        this.selectedCheckboxData.push(item.data)
      }
    }

    isFirstColumn(params) {
      const displayedColumns = params.columnApi.getAllDisplayedColumns();
      const thisIsFirstColumn = displayedColumns[0] === params.column;
      return thisIsFirstColumn;
    }

    createOpenModal() {
      this.modeAction = 1;
      this.usersForm.reset();
      
    }

    performGridActionItem(params) {
      if (params.event.currentTarget.value == 'delete') {
        this.deleteGridRow(params);
      }     
    }
  
    private deleteGridRow(params: any) {
      this.userService.deleteUser(params.params.data.id).subscribe(() => {
        var selectedData = params.params.data;
        this.api.applyTransaction({ remove: [selectedData] });
      });
    }
  
    deleteOpenModal() {
      this.deleteConfirmationMsg = 'Are you sure you want to delete'
      for (let i in this.selectedCheckboxData) {
        if (+i === 0) this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].firstName}`;
        else if (this.selectedCheckboxData.length === +i + 1) this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].firstName}`;
        else this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].firstName}`;
      }
    }

    deleteUser() {
      this.userService.deleteUser(this.selectedCheckboxData[0].id).subscribe((response: any) => {
        this.getUsers();
        this.selectedCheckboxData = []
        $('#closeuserDeleteModal')
      });
    }

  //     // to open delete modal
  // openDelete(item) {
  //   // this.modalService.open(content);
  //   this.selectedRow = item;
  // }

 
  checkLicenseStatus(licenseStatusValue){
    // let b : string = licenseStatusValue.split(': ')[1];
    // if(b === 'true'){
    //   this.license = true;
    // }
    // else{
    //   this.license = false;
    //   this.userCreationForm.patchValue({
    //     licenseType: ''
    //   });
    // }
  }

  moveToDeputy(){
    this.router.navigate([this.tenantId + '/dashboard/adminstration/onsite-employee']);
  }
}