import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import 'rxjs/add/operator/filter';
import { GlobalsettingService } from "src/app/_services/globalsetting/globalsetting.service"
import { LocalStorageService } from "src/app/_services/localStorage/local-storage.service";
import * as _ from 'lodash';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { ActionItemsGridRowRendererComponent } from '../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';

@Component({
  selector: 'app-global-setting',
  templateUrl: './global-setting.component.html',
  styleUrls: ['./global-setting.component.scss']
})
export class GlobalSettingComponent implements OnInit {
  tenantId: string;
  currentUser: any;
  form: FormGroup;
  globalSettingForm: FormGroup;
  performancetemplateForm
  // row data and column definitions  
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi  
  private api: GridApi;  
  private columnApi: ColumnApi;
  defaultColDef:any;
  rowSelection:any
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = [];
  deleteConfirmationMsg = 'Are you sure you want to delete';
  note = 'In case of Date/Month, please add Date/Month in format like dd,dd,dd or MM/MM ex. 01,02';

  constructor(
    private storageService: LocalStorageService,
    private formBuilder: FormBuilder,
    private globalsettingService: GlobalsettingService,
  ) {
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs(); 

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };

    this.globalSettingForm = this.formBuilder.group({
      id: new FormControl(''),
      globalSettingName: new FormControl(''),
      globalSettingType: new FormControl(''),
      globalSettingValue: new FormControl(''),
      isDefault: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.getAllGlobalSetting();
  }

  getAllGlobalSetting() {  
    this.globalsettingService.getAllGlobalSetting().subscribe((response:any) => {
      this.rowData = response;
    });
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
  onGridReady(params): void {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  } 

  // create column definitions  
  private createColumnDefs() {
    return [
      {
        headerName: 'Global Setting Name',
        field: 'globalSettingName',
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Global Setting Type',
        field: 'globalSettingType',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Global Setting Value',
        field: 'globalSettingValue',
        filter: true,
        editable: false,
        sortable: true
      },
      {
        headerName: 'Can Delete',
        valueGetter: function(params){
          if(params.data.isDefault === true){
            return 'No';
          }
          else{
            return 'Yes';
          }
        },
        filter: true,
        editable: false,
        sortable: true
      },
    ]
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = []
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data)
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  createOpenModal() {
    this.modeAction = 1;
    this.globalSettingForm.reset();
    
  }

  createGlobalSetting(){
    let detail = {
      globalSettingName: this.globalSettingForm.get('globalSettingName').value,
      globalSettingType: this.globalSettingForm.get('globalSettingType').value,
      globalSettingValue: this.globalSettingForm.get('globalSettingValue').value,
      isDefault: false,
    }
      this.globalsettingService.createGlobalSetting(detail).subscribe(response => {
        // update grid after creating new lead & close the modal
        this.api.applyTransaction({ add: [response] });
        $('#closeCreateModal').trigger("click");
    });
  }

  editOpenModal() {
    this.modeAction = 2;
    this.globalSettingForm.patchValue(this.selectedCheckboxData[0])
  } 

  updateGlobalVersion(){
    let detail = {
      id: this.globalSettingForm.get('id').value,
      globalSettingName: this.globalSettingForm.get('globalSettingName').value,
      globalSettingType: this.globalSettingForm.get('globalSettingType').value,
      globalSettingValue: this.globalSettingForm.get('globalSettingValue').value,
      isDefault: this.selectedCheckboxData[0].isDefault,
    }
    this.globalsettingService.updateGlobalSetting(detail, this.selectedCheckboxData[0].id).subscribe(res => {
      this.getAllGlobalSetting();
      this.selectedCheckboxData = []
      $('#closeCreateModal').click()  
    });
  }

  deleteOpenModal() {
    this.deleteConfirmationMsg = 'Are you sure you want to delete';
    for (let i in this.selectedCheckboxData) {
      if (+i === 0)
        this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].globalSettingName}`;
      else if (this.selectedCheckboxData.length === +i + 1)
        this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].globalSettingName}`;
      else
        this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].globalSettingName}`;
    }
  }

  deleteGlobalSetting(){
    this.globalsettingService.deleteGlobalSetting(
      this.selectedCheckboxData[0].id
    ).subscribe(() => {
      this.getAllGlobalSetting();
      this.selectedCheckboxData = [];
      $('#closeModalDelete').trigger('click');
    });
  }

}
