import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import 'rxjs/add/operator/filter';
import { HrService } from 'src/app/_services/hr-management/hr.service';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { DatePipe } from '@angular/common';
import { ColumnApi, GridApi } from 'ag-grid-enterprise';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpResponse } from '@angular/common/http';
import { HolidaycalenderService } from 'src/app/_services/holidaycalender/holidaycalender.service';
import { GlobalsettingService } from 'src/app/_services/globalsetting/globalsetting.service';

@Component({
  selector: 'app-leave-application',
  templateUrl: './leave-application.component.html',
  styleUrls: ['./leave-application.component.scss'],
})

export class LeaveApplicationComponent implements OnInit {
  leaveapplicationForm: FormGroup;
  userDropdownList = [];
  tenantId: string;
  userDetails: any;
  date: Date;
  TodayDate: string;
  lafduration: number;
  latduration: number;
  laduration: number;
  leaveduration: number;
  disableEndDate:boolean = false;
  sickleave: number;
  private api: GridApi;
  private columnApi: ColumnApi;
  earnleave: number;
  casualleave: number;
  leaveapprowId: string;
  leaveappupdateId: any;
  userId: string;
  DelegationId: any;
  Delegationuser: any;
  getUserName: any;
  laeditstartDate: any;
  selectedFile: File;
  currentUser: any;
  laattachment: any;
  imageid: any;
  retrievedImage: string;
  url: any;
  imagename: any;
  compensatoryleave: number;
  maxDate: any;
  minDate: any;
  holiday: any;
  leaveStatus: any;
  isHRAdmin: boolean;
  isMaternityLeave: boolean;
  isPaternityLeave: boolean;
  empId: any;
  isLWP: boolean = false;
  isCL: boolean  = false;
  isEL: boolean = false;
  isCompOffNotAvailable: boolean = false;
  paternityLeave: number;
  maternityLeave: number;
  isPaternityLeaveType: boolean = false;
  isMaternityLeaveType: boolean = false;
  disableLeaveAppMonthArray: [] = [];
  isLeaveApplicationDisabled: boolean = false;

  constructor(
    private storageService: LocalStorageService,
    private HrService: HrService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private allocationService: AllocationService,
    private datePipe: DatePipe,
    private snackBar: MatSnackBar,
    private holidaycalenderService: HolidaycalenderService,
    private globalsettingService: GlobalsettingService
  ) {
    this.leaveapplicationForm = this.formBuilder.group({
      userId: new FormControl('', Validators.required),
      leavetype: new FormControl(''),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl(''),
      leaveReason: new FormControl(''),
      applicationDate: new FormControl(''),
      duration: new FormControl(''),
      status: new FormControl('', Validators.required),
      applicationTitle: new FormControl(''),
      appliedByUserId: new FormControl(''),
      delegatedToUserId: new FormControl(''),
      halfDay: new FormControl(''),
    });
  }

  // getter for easy access to form fields
  get f() {
    return this.leaveapplicationForm.controls;
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.userDetails = JSON.parse(this.storageService.get('currentUser'));
    this.getUserList();
    this.date = new Date();
    let latest_date = this.datePipe.transform(this.date, 'yyyy-MM-dd');
    this.TodayDate = latest_date;
    this.getAllHolidays();

    this.isHRAdmin = this.userDetails.roles.includes('RoleHrmAdministrator');
    this.leaveapplicationForm.value.userId = this.userDetails.id + " . " + this.userDetails.firstName + " " + this.userDetails.lastName;
    this.leaveapplicationForm.patchValue(this.leaveapplicationForm.value);
   
    this.leaveapprowId = this.route.snapshot.paramMap.get('id');
    if (this.leaveapprowId) {
      this.getUserLeaveApplicationDetailsById(this.leaveapprowId);
      this.getleaveApplicationsattachments(this.leaveapprowId);
    }
    else{
      this.getUserLeveBalance(this.userDetails.id, null);
      this.leaveapplicationForm.value.leaveStatus = '';
    }
  }

  getAllHolidays() {
    this.holidaycalenderService.getAllHolidays().subscribe((response:any) => {
      this.holiday = response.result;
    });
  }

  getEmployeeLeave(event){
    this.empId = this.leaveapplicationForm.get("userId").value.split(' . ')[0];
    this.getUserLeveBalance(this.empId, null);

  }

  getleavefromduration(e) {
    this.minDate = this.leaveapplicationForm.get("startDate").value;
    if(e.target === undefined) {
      this.disableEndDate = !this.disableEndDate ;
      if(this.disableEndDate) {
        this.leaveduration = 0.5;
        this.latduration = this.lafduration;
        this.leaveapplicationForm.patchValue({
          endDate : this.leaveapplicationForm.get("startDate").value
        });
      }
      else{
        this.leaveduration = 0;
      }
    }
    else{
      this.lafduration = e.target.value;
      this.calculateduration(this.lafduration, this.latduration);
      if (this.leaveapprowId) {
        this.laeditstartDate = this.lafduration;
      }
    }
  }

  // Pop-up message
  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', { duration: 3000 });
  }

  getleavetoduration(e) {
    this.latduration = e.target.value;
    this.maxDate = this.leaveapplicationForm.get("endDate").value;
    if (this.leaveapprowId) {
      this.lafduration = this.laeditstartDate;
    }
    this.calculateduration(this.lafduration, this.latduration);
  }

  //Gets called when the user selects an image
  public onFileChanged(event) {
    //Select File
    this.selectedFile = event.target.files[0];
    this.onUpload();
  }

  //Gets called when the user clicks on submit to upload the image
  onUpload() {
    // get logged in user's details
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    
    //FormData API provides methods and properties to allow us easily prepare form data to be sent with POST HTTP requests.
    const uploadImageData = new FormData();

    uploadImageData.append('file', this.selectedFile, this.selectedFile.name);
    
    //Make a call to the Spring Boot Application to save the image
    this.HrService.uploadImage(
      Number(this.leaveapprowId),
      uploadImageData
    ).subscribe((response: any) => {
      if (response) {
        this.getleaveApplicationsattachments(this.leaveapprowId);
      } else {
        this.getleaveApplicationsattachments(this.leaveapprowId);
      }
    });

    setTimeout(() => {
      this.getleaveApplicationsattachments(this.leaveapprowId);
    }, 400);
  }

  getleaveApplicationsattachments(id) {
    this.HrService.getleaveApplicationsattachments(id).subscribe(
      (response) => {
        this.laattachment = response;
      },
      (error) => {
      }
    );
  }

  deleteleaveApplicationsattachments(value) {
    this.imageid = value.split(': ')[0];

    this.HrService.deleteleaveApplicationsattachments(
      this.leaveapprowId,
      this.imageid
    ).subscribe(
      (response) => {
        window.alert('Delete Successful');
        this.getleaveApplicationsattachments(this.leaveapprowId)
      },
      (error) => {
      }
    );
  }

  downloadleaveApplicationsattachments(value) {
    
    this.imageid = value.split(': ')[0];
    this.imagename = value.split(': ')[1];

    this.HrService.downloadleaveApplicationsattachments(
      this.leaveapprowId,
      this.imageid
    ).subscribe(
      (response: HttpResponse<Blob>) => {
        let filename: string = this.imagename;
          let binaryData = [];
          binaryData.push(response.body);
          let downloadLink = document.createElement('a');
          downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
      },
      (error) => {
      }
    );
    
  }

  openLeaveApp() {
    location.href = '${this.tenantId}/dashboard/leaves/leave-applicationS ';
  }

  getUserLeaveApplicationDetailsById(id) {
    this.HrService.getUserLeaveApplicationDetailsById(id).subscribe(
      (parentResponse) => {
        let leaveBalanceForUser = parentResponse.userId;
        console.log("userId", leaveBalanceForUser);
        this.getUserLeveBalance(leaveBalanceForUser, parentResponse);
      },
      (error) => {
        
      }
    );
  }

  calculateduration(startDate, endDate) {
    let start = new Date(startDate);
    let end = new Date(endDate);
    let totalBusinessDays = 0;

    start.setHours(0,0,0,0);
    end.setHours(0,0,0,0);

    if(this.leaveapplicationForm.get('halfDay').value === true){
      this.leaveduration = 0.5;
      this.leaveapplicationForm.patchValue({
        endDate : this.leaveapplicationForm.get("startDate").value
      });

    }
    else{
      let current = new Date(start);
      current.setDate(current.getDate());
      let day;
      while (current <= end) {
          day = current.getDay();
          let month = current.getMonth() + 1;
          let currentDate = current.getDate().toString().length === 1 ? "0"+current.getDate().toString() : current.getDate().toString();
          let currentMonth = month.toString().length === 1 ? (("0"+month).toString()) : (month.toString());
          let date = current.getFullYear() + '-' + currentMonth + '-' + currentDate;
          if (day >= 1 && day <= 5) {
            if(! (this.holiday.map(p => p.holidayDate).includes(date))){
              ++totalBusinessDays;
            }
          }
          current.setDate(current.getDate() + 1);
      }
    this.leaveduration = totalBusinessDays;
    }
  }

  updateleaveappDetailsbyId() {
    console.log('startDate', this.leaveapplicationForm.get("startDate").value);
    let startMonth : string = this.leaveapplicationForm.get("startDate").value.split('-')[1];
    let endMonth : string = this.leaveapplicationForm.get("endDate").value.split('-')[1];
    console.log('startMonth', startMonth);
    this.globalsettingService.getGlobalSettingByName('DISABLE_LEAVE_APPLICATION_MONTHS').subscribe((response:any[]) => {
      if(response?.length>0){
        this.disableLeaveAppMonthArray = response[0].globalSettingValue?.split(',');
        
        let errorOut : boolean = false;
        if(this.disableLeaveAppMonthArray){
          for(let i=0; i < this.disableLeaveAppMonthArray.length; i++){
            if(this.disableLeaveAppMonthArray[i] === startMonth || this.disableLeaveAppMonthArray[i] === endMonth){
              errorOut = true;
            }
          }
        }
        
        
        if(errorOut){
          this.openSnackBar("You can not apply leave for this month...!!!");
        }
        else{
          var leaveappupdatedetailsdata = {
            id: this.leaveappupdateId,
            userId: this.leaveapplicationForm.get('userId').value.split(' . ')[0],
            leavetype: this.leaveapplicationForm.get('leavetype').value,
            startDate: this.leaveapplicationForm.get('startDate').value,
            endDate: this.leaveapplicationForm.get('endDate').value,
            leaveReason: this.leaveapplicationForm.get('leaveReason').value,
            status: this.leaveapplicationForm.get('status').value,
            applicationTitle: this.leaveapplicationForm.get('applicationTitle').value,
            duration: this.leaveapplicationForm.get('duration').value,
            delegatedToUserId: this.leaveapplicationForm.get('delegatedToUserId').value.split(' . ')[0],
            delegatedToUserName: this.leaveapplicationForm.get('delegatedToUserId').value.split(' . ')[1],
            halfDay: this.leaveapplicationForm.get('halfDay').value,
          };
      
          this.HrService.updateleaveappDetailsbyId(
            leaveappupdatedetailsdata,
            this.leaveappupdateId
          ).subscribe((response: any) => {
            this.backToMyLeave();
          });
        }
      }
    });
  }

  addleaveAppRates() {
    console.log('startDate', this.leaveapplicationForm.get("startDate").value);
    let startMonth : string = this.leaveapplicationForm.get("startDate").value.split('-')[1];
    let endMonth : string = this.leaveapplicationForm.get("endDate").value.split('-')[1];
    console.log('startMonth', startMonth);
    this.globalsettingService.getGlobalSettingByName('DISABLE_LEAVE_APPLICATION_MONTHS').subscribe((response:any[]) => {
      if(response?.length>0){
        this.disableLeaveAppMonthArray = response[0].globalSettingValue?.split(',');
        
        let errorOut : boolean = false;
        if(this.disableLeaveAppMonthArray){
          for(let i=0; i < this.disableLeaveAppMonthArray.length; i++){
            if(this.disableLeaveAppMonthArray[i] === startMonth || this.disableLeaveAppMonthArray[i] === endMonth){
              errorOut = true;
            }
          }
        }

        if(errorOut){
          this.openSnackBar("You can not apply leave for this month...!!!");
        }
        else{
          var addladata = {
            userId: parseInt(
              this.leaveapplicationForm.get('userId').value.toString()
                .substring(0, this.leaveapplicationForm.get('userId').value.toString().indexOf('.'))
            ),
            leavetype: this.leaveapplicationForm.get('leavetype').value,
            startDate: this.leaveapplicationForm.get('startDate').value,
            endDate: this.leaveapplicationForm.get('endDate').value,
            leaveReason: this.leaveapplicationForm.get('leaveReason').value,
            duration: this.leaveapplicationForm.get('duration').value,
            status: this.leaveapplicationForm.get('status').value,
            applicationTitle: this.leaveapplicationForm.get('applicationTitle').value,
            delegatedToUserId: this.leaveapplicationForm.get('delegatedToUserId').value.split(' . ')[0],
            delegatedToUserName: this.leaveapplicationForm.get('delegatedToUserId').value.split(' . ')[1],
            halfDay: this.leaveapplicationForm.get('halfDay').value,
          };
      
          this.HrService.addleaveAppRates(addladata).subscribe(
            (response: any) => {
              this.backToMyLeave();
            }
          );
        }
        
      }
    });
  }

  backToMyLeave() {
    this.router.navigate([this.tenantId + '/dashboard/hr/leaves/leave-first-page']);
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  getUserLeveBalance(id, parentResponse: any) {
    this.isCompOffNotAvailable = false;
    this.isLWP = false;
    this.isEL = false;
    this.isCL = false;
    let el = 0.25;
    this.HrService.getUserLeveBalance(id).subscribe(
      (response) => {
        for (let item of response.result) {
          this.sickleave = response.result.filter((leave) => leave.leaveType === 'Sick Leave')[0] ? 
          response.result.filter((leave) => leave.leaveType === 'Sick Leave')[0].balance : 0;
          this.earnleave = response.result.filter((leave) => leave.leaveType === 'Earned Leave')[0] ?
          response.result.filter((leave) => leave.leaveType === 'Earned Leave')[0].balance : 0;
          this.compensatoryleave = response.result.filter((leave) => leave.leaveType === 'Compensatory leave')[0] ?
          response.result.filter((leave) => leave.leaveType === 'Compensatory leave')[0].balance : 0;
          this.casualleave = response.result.filter((leave) => leave.leaveType === 'Casual Leave')[0] ?
          response.result.filter((leave) => leave.leaveType === 'Casual Leave')[0].balance : 0 ;
          this.paternityLeave = response.result.filter((leave) => leave.leaveType === 'Paternity Leave')[0] ?
          response.result.filter((leave) => leave.leaveType === 'Paternity Leave')[0].balance : 0;
          this.maternityLeave = response.result.filter((leave) => leave.leaveType === 'Maternity Leave')[0] ?
          response.result.filter((leave) => leave.leaveType === 'Maternity Leave')[0].balance : 0 ;
        }
        if(this.earnleave < 0 || this.earnleave === 0 || this.earnleave === 0.25){
          this.isEL = true;
        }
        if(this.casualleave < 0 || this.casualleave === 0){
          this.isCL = true;
        }
        if(this.compensatoryleave < 0 || this.compensatoryleave === 0){
          this.isCompOffNotAvailable = true;
        }
        if(this.paternityLeave > 0){
          this.isPaternityLeaveType = true;
        }
        if(this.maternityLeave > 0){
          this.isMaternityLeaveType = true;
        }
        if(this.casualleave < 0 && this.casualleave === 0 && this.earnleave < 0 && this.earnleave === 0 || this.earnleave === el){
          this.isLWP = true;
        }

        if (parentResponse) {
          this.leaveapplicationForm.patchValue({
            userId: parentResponse.userId + " . " + parentResponse.userIdUserName,
            leavetype: parentResponse.leavetype,
            startDate: parentResponse.startDate,
            endDate: parentResponse.endDate,
            applicationDate: parentResponse.applicationDate,
            applicationTitle: parentResponse.applicationTitle,
            appliedByUserId: parentResponse.appliedByUserId,
            appliedByUserName: parentResponse.appliedByUserName,
            delegatedToUserId: parentResponse.delegatedToUserId + ' . ' + parentResponse.delegatedToUserName,
            duration: parentResponse.duration,
            halfDay: parentResponse.halfDay,
            leaveReason: parentResponse.leaveReason,
            status: parentResponse.status,
            userIdUserName: parentResponse.userIdUserName,
          });
          if(parentResponse.delegatedToUserId === null){
            this.leaveapplicationForm.value.delegatedToUserId = '',
            this.leaveapplicationForm.patchValue(this.leaveapplicationForm.value);
          }
          this.leaveappupdateId = parentResponse.id;
          this.calculateduration(parentResponse.startDate, parentResponse.endDate);
          this.laeditstartDate = parentResponse.startDate;
          this.leaveStatus = parentResponse.status;
          if(parentResponse?.leavetype === 'Maternity Leave'){
            this.isMaternityLeave = parentResponse.leaveType.includes('Maternity Leave');
            this.isMaternityLeaveType = true;
          }
          else if(parentResponse?.leavetype === 'Paternity Leave') {
            this.isPaternityLeave = parentResponse.leaveType.includes('Paternity Leave');
            this.isPaternityLeaveType = true;
          }
          else if(parentResponse?.leavetype === "Earned Leave") {
            this.isLWP = false;
            this.isEL = false;
          }
          else if(parentResponse?.leavetype === "Casual Leave"){
            this.isLWP = false;
            this.isCL = false;
          }
          else if(parentResponse?.leavetype === "Compensatory leave"){
            this.isCompOffNotAvailable = false;
          }
        }

      }
    );
  }
}