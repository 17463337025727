import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TaskService } from 'src/app/_services/task/task.service';
import { ActionItemsGridRowRendererComponent } from 'src/app/components/_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { ProjectTaskService} from 'src/app/_services/project_task/project-task.service'
import { ProjectService } from 'src/app/_services/project/project.service';
import { HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-project-task',
  templateUrl: './project-task.component.html',
  styleUrls: ['./project-task.component.scss']
})
export class ProjectTaskComponent implements OnInit {
  projectTaskGridForm
  // row data and column definitions  
  rowData: any;
  public columnDefs: ColDef[]; 
  modeAction:number = 1 //1 - create // 2 - update
  // gridApi and columnApi 
  private api: GridApi;  
  private columnApi: ColumnApi;  
    defaultColDef:any;
    rowSelection:any
    tenantId: string;
    gridApi: any;
    gridColumnApi: any;
    form: FormGroup;
    userDetails: any;
    gridLength = 0;
    sampleGridFrameworkComponents: any;
    selectedCheckboxData = [];
    deleteConfirmationMsg = 'Are you sure you want to delete';
    projectDropdownList = [];
    systemInformation: any; //Response of API call getSystemInformation()
    systemArray: any = []; //Array of all system
    subSystemArray: any = []; //Array of all sub-system Name
    selectedSystem: any; //Selected System in HTML
    selectedSubSystem: any; //Selected System in HTML
    subSubSystemArray: any = []; //Array of all Sub sub-system Name
    selectedFile: File;
    orphanFiles: File [];
    projectTaskAttachments: any;
    attachmentId: any;
    attachemntName: any;
    ignoreSnackbar : boolean;
    projectTaskId: any;
    subProjectDropdownList: any = [];
    selectedProjectId: any;
    isAdministrator: boolean = false; 

  constructor(
    private taskService: TaskService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private router: Router,
    private storageService: LocalStorageService,
    private projecttaskService: ProjectTaskService,
    private projectService: ProjectService,
  ) { 
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    }; 
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();  
    
    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent
    };

    this.form = this.formBuilder.group({
      file: ['', Validators.required]
    });

    this.projectTaskGridForm = new FormGroup({
      id: new FormControl(''),
      name: new FormControl(''),
      projectId: new FormControl(''),
      projectName: new FormControl(''),
      system: new FormControl(''),
      subSystem: new FormControl(''),
      subSubSystem: new FormControl(''),
      description: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
      taskOwnerId: new FormControl(''),
      taskOwnerName: new FormControl(''),
      estimatedTimeInHours: new FormControl(''),
      parentId: new FormControl(''),
      parentName: new FormControl(''),
      status: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.tenantId = this.storageService.get('tenantId');
    this.userDetails = JSON.parse(this.storageService.get('currentUser'));
    this.isAdministrator = this.userDetails.roles.includes('RoleAdministrator');

    this.getSystemInformation();
    this.getprojectsList();
    this.getProjectTaskForCurrentUser();
  }

  getProjectTaskForCurrentUser(){
    this.taskService.getProjectTaskForCurrentUser(this.userDetails.id).subscribe((response: any) => {
      this.rowData = response.result;
    });
  }

   // one grid initialisation, grap the APIs and auto resize the columns to fit the available space  
 onGridReady(params): void {
  this.api = params.api;
  this.columnApi = params.columnApi;
  this.api.sizeColumnsToFit();
}  

// create column definitions  
private createColumnDefs() {
  return [
  {
    headerName: 'Task Name',
    field: 'name',
    filter: true,
    editable: false,
    sortable: true,
    headerCheckboxSelection: this.isFirstColumn,
    checkboxSelection: this.isFirstColumn,
  },
  {
    headerName: 'Task Owner Name',
    field: 'taskOwnerName',
    filter: true,
    editable: false,
    sortable: true
  },
  {
    headerName: 'Project Name',
    field: 'projectName',
    filter: true,
    editable: false,
    sortable: true
  },
  {
    headerName: 'System',
    field: 'system',
    filter: true,
    editable: false,
    sortable: true
  },
  {
    headerName: 'Start Date',
    field: 'startDate',
    filter: true,
    editable: false,
    sortable: true
  },
  {
    headerName: 'EndDate',
    field: 'endDate',
    filter: true,
    editable: false,
    sortable: true
  },
  {
    headerName: 'Estimated Time',
    field: 'estimatedTimeInHours',
    filter: true,
    editable: false,
    sortable: true
  },
  {
    headerName: 'Status',
    field: 'status',
    filter: true,
    editable: false,
    sortable: true,
    hide: true,
  }
 ]
}

onSelectionChanged(event) {
  this.selectedCheckboxData = []
  let select = event.api.getSelectedNodes();
  for (let item of select) {
    this.selectedCheckboxData.push(item.data)
  }
}

isFirstColumn(params) {
  const displayedColumns = params.columnApi.getAllDisplayedColumns();
  const thisIsFirstColumn = displayedColumns[0] === params.column;
  return thisIsFirstColumn;
}

timesheetPage(){
  this.router.navigate([this.tenantId + '/dashboard/hr/timesheet/time-sheet']);
}

// Pop-up message
openSnackBar(message: string) {
  this.snackBar.open(message, 'Close', { duration: 3000 });
}

getprojectsList(){
  this.projectDropdownList = [];
  let ignoreArchived : boolean = true;
  this.projectService.getProjectANDSubProjectByUserId(this.userDetails.id, ignoreArchived).subscribe((response: any) => {
    for (let item of response) {
      this.projectDropdownList.push(`${item.id} . ${item.projectName}`);
    }
  });
}

getProjectById(id){
  this.projectService.getProjectForTask(id).subscribe((response : any) => {
      this.projectTaskGridForm.value.projectName = id + " . " + response.projectName;
      let project = this.projectTaskGridForm.value.projectName;
      this.projectTaskGridForm.patchValue({projectName: project.toString()});
  }, error => {
    this.openSnackBar(error);
  });
}

editOpenModal() {
  this.modeAction = 2;
  this.projectTaskId = this.selectedCheckboxData[0].id;
  this.getProjectTaskAttachments(this.projectTaskId);
  this.projectTaskGridForm.patchValue(this.selectedCheckboxData[0]);
  this.getSystemInformation();
  this.getSubSystemInformation(this.selectedCheckboxData[0].system);
  this.getSubSubSystemInformation(this.selectedCheckboxData[0].subSystem);
  this.getProjectById(this.selectedCheckboxData[0].projectId);
  this.projectTaskGridForm.value.taskOwnerName = this.projectTaskGridForm.value.taskOwnerId + " . " + this.projectTaskGridForm.value.taskOwnerName;
  this.projectTaskGridForm.value.parentName = this.projectTaskGridForm.value.parentId + " . " + this.projectTaskGridForm.value.parentName;
  this.projectTaskGridForm.value.estimatedTime = this.projectTaskGridForm.value.estimatedTime;
  this.projectTaskGridForm.value.subSystem = this.selectedCheckboxData[0].subSystem;
  this.projectTaskGridForm.value.subSubSystem = this.selectedCheckboxData[0].subSubSystem;
  this.projectTaskGridForm.patchValue(this.projectTaskGridForm.value);
}

createOpenModal(){
  this.modeAction = 1;
    this.projectTaskGridForm.reset();
    this.projectTaskAttachments = [];
    this.orphanFiles= [];
    this.projectTaskGridForm.patchValue({
      id: '',
      name: '',
      projectId: '',
      projectName:'',
      system:'',
      subSystem: '',
      subSubSystem: '',
      description: '',
      startDate: '',
      endDate: '',
      taskOwnerName: this.userDetails.id + " . " + this.userDetails.firstName + " " + this.userDetails.lastName,
      estimatedTime: '',
      parentId: '',
      parentName: '',
      status: '',
    })
}

createProjectTask() {
  let data = {
    name: this.projectTaskGridForm.get('name').value,
    projectId:  parseInt((this.projectTaskGridForm.get('projectName').value).toString().substring(0, ((this.projectTaskGridForm.get('projectName').value).toString().indexOf('.')))),
    projectName: ((this.projectTaskGridForm.get('projectName').value).toString().substring(((this.projectTaskGridForm.get('projectName').value).toString().indexOf('.'))+1)).trim(),
    system: this.projectTaskGridForm.get('system').value,
    subSystem: this.projectTaskGridForm.get('subSystem').value,
    subSubSystem: this.projectTaskGridForm.get('subSubSystem').value,
    description: this.projectTaskGridForm.get('description').value,
    startDate: this.projectTaskGridForm.get('startDate').value,
    endDate: this.projectTaskGridForm.get('endDate').value,
    taskOwnerId: parseInt((this.projectTaskGridForm.get('taskOwnerName').value).toString().substring(0, ((this.projectTaskGridForm.get('taskOwnerName').value).toString().indexOf('.')))),
    taskOwnerName: ((this.projectTaskGridForm.get('taskOwnerName').value).toString().substring(((this.projectTaskGridForm.get('taskOwnerName').value).toString().indexOf('.'))+1)).trim(),
    estimatedTimeInHours : this.projectTaskGridForm.get('estimatedTimeInHours').value,
    parentId: parseInt((this.projectTaskGridForm.get('parentName').value).toString().substring(0, ((this.projectTaskGridForm.get('parentName').value).toString().indexOf('.')))),
    parentName: ((this.projectTaskGridForm.get('parentName').value).toString().substring(((this.projectTaskGridForm.get('parentName').value).toString().indexOf('.'))+1)).trim(),
    status: this.projectTaskGridForm.get('status').value,
  }
  this.taskService.createProjectTasks(data).subscribe((response: any) => {
    if(this.orphanFiles && this.orphanFiles.length > 0) {
      this.projectTaskId = response.id;
      this.ignoreSnackbar = true;
      this.orphanFiles.forEach( (file) => {
        this.selectedFile = file;
        this.onUploadAttachment();
      })
    }
    this.api.applyTransaction({ add: [response] });
    this.getProjectTaskForCurrentUser();
    $('#closeCreateModal').trigger("click");
  });
}

updateProjectTask() {
  let data = {
    id: this.projectTaskGridForm.get('id').value,
    name: this.projectTaskGridForm.get('name').value,
    projectId:  parseInt((this.projectTaskGridForm.get('projectName').value).toString().substring(0, ((this.projectTaskGridForm.get('projectName').value).toString().indexOf('.')))),
    projectName: ((this.projectTaskGridForm.get('projectName').value).toString().substring(((this.projectTaskGridForm.get('projectName').value).toString().indexOf('.'))+1)).trim(),
    system: this.projectTaskGridForm.get('system').value,
    subSystem: this.projectTaskGridForm.get('subSystem').value,
    subSubSystem: this.projectTaskGridForm.get('subSubSystem').value,
    description: this.projectTaskGridForm.get('description').value,
    startDate: this.projectTaskGridForm.get('startDate').value,
    endDate: this.projectTaskGridForm.get('endDate').value,
    taskOwnerId: parseInt((this.projectTaskGridForm.get('taskOwnerName').value).toString().substring(0, ((this.projectTaskGridForm.get('taskOwnerName').value).toString().indexOf('.')))),
    taskOwnerName: ((this.projectTaskGridForm.get('taskOwnerName').value).toString().substring(((this.projectTaskGridForm.get('taskOwnerName').value).toString().indexOf('.'))+1)).trim(),
    estimatedTimeInHours : this.projectTaskGridForm.get('estimatedTimeInHours').value,
    parentId: parseInt((this.projectTaskGridForm.get('parentName').value).toString().substring(0, ((this.projectTaskGridForm.get('parentName').value).toString().indexOf('.')))),
    parentName: ((this.projectTaskGridForm.get('parentName').value).toString().substring(((this.projectTaskGridForm.get('parentName').value).toString().indexOf('.'))+1)).trim(),
    status: this.projectTaskGridForm.get('status').value,
  }
      this.taskService.updateProjectTasks(data, this.projectTaskId).subscribe(response => {
        this.api.applyTransaction({ add: [response] });
        this.getProjectTaskForCurrentUser();
        this.onUploadAttachment();
        $('#closeCreateModal').trigger("click");
    });
}

getSystemInformation(){
  if(! this.systemInformation) {
    this.taskService.getSystemInformation().subscribe((response: any) => {
      this.systemInformation = response;
      this.systemArray = Object.keys(response);
    });
  }else{
    this.systemArray = Object.keys(this.systemInformation);
  }
}

getSubSystemInformation(system){
  if(this.modeAction === 2){
    this.selectedSystem = system;
  }
  else{
    this.selectedSystem = system.target.value;
  }
  this.projectTaskGridForm.value.subSystem = '';
  this.projectTaskGridForm.value.subSubSystem = '';
  this.projectTaskGridForm.patchValue(this.projectTaskGridForm.value);
  if(this.systemArray){
    this.subSystemArray = Object.keys(this.systemInformation[this.selectedSystem]);
  }
  else{
    alert("Please Select System Information First");
  }
};

getSubSubSystemInformation(subSystem){
  if(this.modeAction === 2){
    this.selectedSubSystem = subSystem;
  }
  else{
    this.selectedSubSystem = subSystem.target.value;
  }
  this.projectTaskGridForm.value.subSubSystem = '';
  this.projectTaskGridForm.patchValue(this.projectTaskGridForm.value);
  if(this.subSystemArray){
    this.subSubSystemArray = this.systemInformation[this.selectedSystem][this.selectedSubSystem];
  }
  else{
    alert("Please Select System & Sub System Information First");
  }
};

public onFileChanged(event) {
  //Select File
  this.selectedFile = event.target.files[0];
}

onFileChangedBeforeTaskCreation(event){
  if(event.target.files[0]) {
    this.orphanFiles.push(event.target.files[0] as never);
    this.projectTaskAttachments.push(event.target.files[0].name as never);
  }
}

onUploadAttachment() {
  const fileData = new FormData();    

  fileData.append('file', this.selectedFile, this.selectedFile.name);
  
  this.projecttaskService.uploadProjectTaskAttachments(
    Number(this.projectTaskId),
    fileData
  ).subscribe((response: any) => {
    if (response) {
      if(! this.ignoreSnackbar) {
        window.location.reload();
      }
    } else {
     window.location.reload();
    }
  });

  setTimeout(() => {
    this.getProjectTaskAttachments(this.projectTaskId);
  }, 400);
}

getProjectTaskAttachments(taskId){
  this.projecttaskService.getProjectTaskAttachments(taskId).subscribe(
    (response) => {
      this.projectTaskAttachments = response;
    },
    (error) => {
    }
  );
}

deleteProjectTaskAttachments(value) {
  this.attachmentId = value.split(': ')[0];

  this.projecttaskService.deleteProjectTaskAttachments(
    this.projectTaskId,
    this.attachmentId
  ).subscribe(
    (response) => {
      window.alert('Delete Successful');
      this.getProjectTaskAttachments(this.projectTaskId)
    },
    (error) => {
    }
  );
}

deleteProjectTaskAttachmentsBeforeTaskCreation(value) {
  this.projectTaskAttachments = this.projectTaskAttachments.filter((attachment) => {
    return attachment != value;
  });

  this.orphanFiles = this.orphanFiles.filter ((file) => {
    return file.name != value;
  })
}

downloadProjectTaskAttachments(value) {
  this.attachmentId = value.split(': ')[0];
  this.attachemntName = value.split(': ')[1];

  this.projecttaskService.downloadProjectTaskAttachments(
    this.projectTaskId,
    this.attachmentId
  ).subscribe(
    (response: HttpResponse<Blob>) => {
      let filename: string = this.attachemntName;
        let binaryData = [];
        binaryData.push(response.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
        downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
    },
    (error) => {
    }
  );
}

}
