import { ProjectService } from 'src/app/_services/project/project.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { Router } from '@angular/router';
import { ColDef, ColumnApi, GridApi } from 'ag-grid-enterprise';
import { LocalStorageService } from 'src/app/_services/localStorage/local-storage.service';
import { AllocationService } from 'src/app/_services/resource-allocation/allocation.service';
import { TimesheetService } from 'src/app/_services/timesheet/timesheet.service';
import { ActionItemsGridRowRendererComponent } from '../../../../_shared/action-items-grid-row-renderer/action-items-grid-row-renderer.component';
import { convertYYYYMMDDtoDDMMYYYY } from 'src/app/_helpers/utils';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProjectTaskService } from 'src/app/_services/project_task/project-task.service';
import { ProjectResourcesService } from './../../../../../_services/projectResources/project-resources.service';
import { TaskService } from 'src/app/_services/task/task.service';

@Component({
  selector: 'app-time-sheet',
  templateUrl: './time-sheet.component.html',
  styleUrls: ['./time-sheet.component.scss'],
})
export class TimeSheetComponent implements OnInit {
  timesheetForm;
  // row data and column definitions
  rowData: any;
  public columnDefs: ColDef[];
  modeAction: number = 1; //1 - create // 2 - update
  lafduration: number;
  latduration: number;
  laduration: number;
  th: number;
  date: Date;
  pjCode: number;
  // gridApi and columnApi
  private api: GridApi;
  private columnApi: ColumnApi;
  defaultColDef: any;
  rowSelection: any;
  tenantId: string;
  userDetails: any;
  form: FormGroup;
  selectedRow: any;
  modalService: any;
  currentUser: any;
  jsonData: any;
  emailPattern = '^[a-z0-9._%+-]+\\.[a-z]{2,4}$';
  gridLength = 0;
  sampleGridFrameworkComponents: any;
  selectedCheckboxData = [];
  deleteConfirmationMsg = 'Are you sure you want to delete';
  userDropdownList = [];
  projectDropdownList = [];
  projectCode: number;
  projname: any;
  proj = [];
  getProjectDetailsList: any;
  getUserName: any;
  projectDetailList: any;
  endDate: any;
  isManager: any;
  currentDate: any;
  today= new Date();
  isDisableEdit: boolean = false;
  projectTaskDropdown: any = [];
  subProjectDropdownList: any = [];
  selectedProjectId: any;
  leadEngineer: any;
  isAdministrator: boolean = false; 

  constructor(
    private storageService: LocalStorageService,
    private timesheetService: TimesheetService,
    private formBuilder: FormBuilder,
    private allocationService: AllocationService,
    private projectService: ProjectService,
    private datePipe: DatePipe,
    private router: Router,
    private snackBar: MatSnackBar,
    private projectTaskService: ProjectTaskService,
    private projectResourcesService: ProjectResourcesService,
    private taskService: TaskService,
  ) {
    this.defaultColDef = {
      flex: 1,
      minWidth: 100,
      resizable: true,
    };
    this.rowSelection = 'multiple';
    this.columnDefs = this.createColumnDefs();

    this.sampleGridFrameworkComponents = {
      actionRowRendererComponent: ActionItemsGridRowRendererComponent,
    };

    this.form = this.formBuilder.group({
      file: ['', Validators.required],
    });

    this.timesheetForm = this.formBuilder.group({
      id: new FormControl(''),
      assignedTo: new FormControl('', Validators.required),
      projectId: new FormControl('', Validators.required),
      projectName: new FormControl(''),
      subprojectId: new FormControl(''),
      subprojectName: new FormControl(''),
      projectManagerId: new FormControl('', Validators.required),
      description: new FormControl(''),
      startDate: new FormControl('', Validators.required),
      endDate: new FormControl(''),
      totalHours: new FormControl(''),
      status: new FormControl(''),
      approvedHours: new FormControl(''),
      efficiency: new FormControl(''),
      projectManagerName: new FormControl(''),
      assignedToName: new FormControl(''),
      leadEngineerId: new FormControl(''),
      leadEngineerName: new FormControl(''),
      part: new FormControl(''),
      commodityId: new FormControl(''),
      commodityName: new FormControl(''),
      projectTaskName: new FormControl(''),
      projectTaskId: new FormControl(''),
      system: new FormControl(''),
      subSystem: new FormControl(''),
      subSubSystem: new FormControl(''),
    });
  }

  ngOnInit(): void {
    this.currentUser = JSON.parse(this.storageService.get('currentUser'));
    this.isAdministrator = this.currentUser.roles.includes('RoleAdministrator');
    this.tenantId = this.storageService.get('tenantId');
    this.getTimesheet();
    this.getUserList();
    this.getProjectDetails();
    this.isManager = this.currentUser.admin ||this.currentUser.roles.includes('RoleReportsExtractor');
  }

  gettsfromduration(e) {
    this.lafduration = e.target.value;
  }

  // Pop-up message
  openSnackBar(message: string) {
    this.snackBar.open(message, 'Close', { duration: 3000 });
  }

  gettstoduration(e) {
    this.latduration = e.target.value;
    let toDate = new Date(this.latduration);
    let fromDate = new Date(this.lafduration);
    let differenceInTime = toDate.getTime() - fromDate.getTime();
    let differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    this.th = (differenceInDays + 1) * 8;
  }

  getTimesheet() {
    this.timesheetService.getAllTimesheet(this.currentUser.id).subscribe((response: any) => {
      this.rowData = response.result;
    });
  }

  // one grid initialisation, grap the APIs and auto resize the columns to fit the available space
  onGridReady(params): void {
    this.api = params.api;
    this.columnApi = params.columnApi;
    this.api.sizeColumnsToFit();
  }

  // create column definitions
  private createColumnDefs() {
    return [
      {
        headerName: 'Date',
        field: 'startDate',
        filter: true,
        editable: false,
        sortable: true,
        headerCheckboxSelection: this.isFirstColumn,
        checkboxSelection: this.isFirstColumn,
      },
      {
        headerName: 'Assigned User',
        field: 'assignedToName',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Project Name',
        field: 'projectName',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Project Task',
        field: 'projectTaskName',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Total Hours',
        field: 'totalHours',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Approved Hours',
        field: 'approvedHours',
        filter: true,
        editable: false,
        sortable: true,
      },
      {
        headerName: 'Description',
        field: 'description',
        filter: true,
        editable: false,
        sortable: true,
        hide: true
      },
      {
        headerName: 'System',
        field: 'system',
        filter: true,
        editable: false,
        sortable: true,
        hide: true
      },
      {
        headerName: 'Sub System',
        field: 'subSystem',
        filter: true,
        editable: false,
        sortable: true,
        hide: true
      },
      {
        headerName: 'Sub Sub System',
        field: 'subSubSystem',
        filter: true,
        editable: false,
        sortable: true,
        hide: true
      },
      {
        headerName: 'Status',
        field: 'status',
        //autoSizeColumns: true,
        maxWidth: 150,
        cellStyle: (params) => {
          if (params.value === 'Save' || params.value === 'save') {
            return { color: 'blue', 'font-weight': 'bold' };
          } else if (params.value === 'Submit' || params.value === 'submit') {
            return { color: 'orange', 'font-weight': 'bold' };
          } else if (params.value === 'Approved' || params.value === 'approved') {
            return { color: 'green', 'font-weight': 'bold' };
          } else if (params.value === 'Reject' || params.value === 'reject') {
            return { color: 'red', 'font-weight': 'bold' };
          } else if (params.value === 'Withdraw' || params.value === 'withdraw') {
            return { color: 'grey', 'font-weight': 'bold' };
          }
          return null;
        },
        filter: true,
        editable: false,
        sortable: true,
      },
    ];
  }

  onSelectionChanged(event) {
    this.selectedCheckboxData = [];
    let select = event.api.getSelectedNodes();
    for (let item of select) {
      this.selectedCheckboxData.push(item.data);
    }
    this.currentDate = this.datePipe.transform(this.today, 'yyyy-MM-dd');
    if(this.currentDate > this.selectedCheckboxData[0].startDate){
      if(this.selectedCheckboxData[0].status === 'Submit'){
        this.isDisableEdit = true;
      }
    }
  }

  isFirstColumn(params) {
    const displayedColumns = params.columnApi.getAllDisplayedColumns();
    const thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  // createTimesheet() {
  //   let endDate = this.timesheetForm.get('endDate').value;
  //   let startDate = this.timesheetForm.get('startDate').value

  //   if(endDate){
  //     if(startDate === endDate){
  //       let details = {
  //         id: '',
  //         assignedTo: parseInt(this.timesheetForm.get('assignedTo').value.toString().split(" . ")[0]),
  //         assignedToName: this.timesheetForm.get('assignedTo').value.split(' . ')[1],
  //         projectCode: this.timesheetForm.get('projectCode').value.toString().split(" . ")[0],
  //         projectName: this.timesheetForm.get('projectCode').value.toString().split(" . ")[1],  
  //         projectManagerId: parseInt(this.timesheetForm.get('projectManagerId').value.split(' . ')[0]),
  //         projectManagerName: this.timesheetForm.get('projectManagerId').value.split(' . ')[1],
  //         description: this.timesheetForm.get('description').value,
  //         startDate: this.timesheetForm.get('startDate').value,
  //         endDate: this.timesheetForm.get('endDate').value,
  //         totalHours: this.timesheetForm.get('totalHours').value,
  //         status: this.timesheetForm.get('status').value,
  //       };
  //       this.timesheetService.createTimesheet(details).subscribe((response: any) => {
  //         // update grid after creating new lead & close the modal
  //         this.api.applyTransaction({ add: [response] });
  //         $('#closeModalTimeSheet').trigger('click');
  //       });
  //     }
  //     else{
  //       let diff = this.calculateDiff(startDate,endDate);
  //       let totalHrs = this.timesheetForm.get('totalHours').value;
  //       if(diff){
  //         if(totalHrs){
  //           totalHrs = totalHrs/diff;
  //           totalHrs = parseFloat(totalHrs).toFixed(2);
  //         }
  //       }
  //       for(let i=0;i<diff;i++){
  //         let details = {
  //           id: '',
  //           assignedTo: parseInt(this.timesheetForm.get('assignedTo').value.toString().split(" . ")[0]),
  //           assignedToName: this.timesheetForm.get('assignedTo').value.split(' . ')[1],
  //           projectCode: this.timesheetForm.get('projectCode').value.toString().split(" . ")[0],
  //           projectName: this.timesheetForm.get('projectCode').value.toString().split(" . ")[1],
  //           projectManagerId: parseInt(this.timesheetForm.get('projectManagerId').value.split(' . ')[0]),
  //           projectManagerName: this.timesheetForm.get('projectManagerId').value.split(' . ')[1],
  //           description: this.timesheetForm.get('description').value,
  //           startDate: startDate,
  //           totalHours: totalHrs,
  //           status: this.timesheetForm.get('status').value,
  //         };
  //         if(startDate!=endDate){
  //           startDate = new Date(new Date(startDate).setDate(new Date(startDate).getDate() + 1));
  //           startDate = [startDate.getFullYear(), ("0" + (startDate.getMonth() + 1)).slice(-2), ("0" + startDate.getDate()).slice(-2)].join("-");
  //         }
  //         this.timesheetService.createTimesheet(details).subscribe((response: any) => {
  //           // update grid after creating new lead & close the modal
  //           this.api.applyTransaction({ add: [response] });
  //         });
  //       }
  //       $('#closeModalTimeSheet').trigger('click');
  //     }
  //   }
  //   else{
  //     let details = {
  //       id: '',
  //       assignedTo: parseInt(this.timesheetForm.get('assignedTo').value.toString().split(" . ")[0]),
  //       assignedToName: this.timesheetForm.get('assignedTo').value.split(' . ')[1],
  //       projectCode: this.timesheetForm.get('projectCode').value.toString().split(" . ")[0],
  //       projectName: this.timesheetForm.get('projectCode').value.toString().split(" . ")[1],  
  //       projectManagerId: parseInt(this.timesheetForm.get('projectManagerId').value.split(' . ')[0]),
  //       projectManagerName: this.timesheetForm.get('projectManagerId').value.split(' . ')[1],
  //       description: this.timesheetForm.get('description').value,
  //       startDate: this.timesheetForm.get('startDate').value,
  //       endDate: this.timesheetForm.get('endDate').value,
  //       totalHours: this.timesheetForm.get('totalHours').value,
  //       status: this.timesheetForm.get('status').value,
  //     };
  //     this.timesheetService.createTimesheet(details).subscribe((response: any) => {
  //       // update grid after creating new lead & close the modal
  //       this.api.applyTransaction({ add: [response] });
  //       $('#closeModalTimeSheet').trigger('click');
  //     });
  //   }
  // }

  createTimesheet() {
    let details = {
      assignedTo: parseInt(this.timesheetForm.get('assignedTo').value.toString().split(" . ")[0]),
      assignedToName: this.timesheetForm.get('assignedTo').value.split(' . ')[1],
      projectId: parseInt(this.timesheetForm.get('projectId').value.toString().split(" . ")[0]),
      projectName: this.timesheetForm.get('projectId').value.toString().split(" . ")[1],
      subprojectId: parseInt(this.timesheetForm.get('subprojectId').value.toString().split(" . ")[0]),
      subprojectName: this.timesheetForm.get('subprojectId').value.toString().split(" . ")[1],
      projectManagerId: parseInt(this.timesheetForm.get('projectManagerId').value.split(' . ')[0]),
      projectManagerName: this.timesheetForm.get('projectManagerId').value.split(' . ')[1],
      description: this.timesheetForm.get('description').value,
      startDate: this.timesheetForm.get('startDate').value,
      endDate: this.timesheetForm.get('endDate').value,
      totalHours: this.timesheetForm.get('totalHours').value,
      status: this.timesheetForm.get('status').value,
      projectTaskName: this.timesheetForm.get('projectTaskName').value.toString().split(" . ")[1],
      projectTaskId: parseInt(this.timesheetForm.get('projectTaskName').value.toString().split(" . ")[0]),
      leadEngineerName: this.timesheetForm.get('leadEngineerName').value.toString().split(" . ")[1],
      leadEngineerId: parseInt(this.timesheetForm.get('leadEngineerName').value.toString().split(" . ")[0])
    };
    this.timesheetService.createTimesheet(details).subscribe((response: any) => {
      // update grid after creating new lead & close the modal
      this.api.applyTransaction({ add: [response] });
      $('#closeModalTimeSheet').trigger('click');
    });
  }

  calculateDiff(startDate, endDate){
    let start = new Date(startDate);
    let end = new Date(endDate);
    let totalBusinessDays = 0;

    start.setHours(0,0,0,0);
    end.setHours(0,0,0,0);
      let current = new Date(start);
      current.setDate(current.getDate());
      let day;
      while (current <= end) {
          day = current.getDay();
          if (day >= 1 && day <= 5) {
              ++totalBusinessDays;
          }
          current.setDate(current.getDate() + 1);
      }
    let diff = totalBusinessDays;
    return diff;
  }

  performGridActionItem(params) {
    if (params.event.currentTarget.value == 'delete') {
      this.deleteGridRow(params);
    }
  }

  private deleteGridRow(params: any) {
    this.timesheetService.deleteTimesheet(params.params.data.id).subscribe(() => {
      var selectedData = params.params.data;
      this.api.applyTransaction({ remove: [selectedData] });
    });
  }

  editOpenModal() {
    if(this.isDisableEdit){
      this.openSnackBar('You cannot edit old Timesheet Report please contact Your Manager');
      this.isDisableEdit = false;
      this.selectedCheckboxData = [];
    }
    else{
      this.modeAction = 2;
      this.timesheetService.getTimesheet(this.selectedCheckboxData[0].id).subscribe((response: any) => {
        let editdetails = {
          assignedTo: response.assignedTo + " . " + response.assignedToName,  
          projectId: response.projectId + " . " + response.projectName,
          subprojectId: response.subprojectId + " . " + response.subprojectName,
          projectManagerId: response.projectManagerId + " . " + response.projectManagerName,
          projectTaskName: response.projectTaskId + " . " + response.projectTaskName,
          description: response.description,
          startDate: response.startDate,
          endDate: response.endDate,
          totalHours: response.totalHours,
          status: response.status,
          leadEngineerName: response.leadEngineerName,
        };
        this.timesheetForm.patchValue(editdetails);
      });
      this.selectedCheckboxData[0].assignedTo = this.getUserID(this.selectedCheckboxData[0].assignedTo);
      this.selectedCheckboxData[0].projectId = this.getProjectID(parseInt(this.selectedCheckboxData[0].projectId));
      this.selectedCheckboxData[0].projectManagerId = this.getUserID(this.selectedCheckboxData[0].projectManagerId);
      this.timesheetForm.patchValue(this.selectedCheckboxData[0]);
      this.getTaskDetails(this.selectedCheckboxData[0].projectTaskId);
    }
  }

  createOpenModal() {
    this.modeAction = 1;
    this.timesheetForm.reset();
    this.timesheetForm.patchValue({
      id: '',
      assignedTo: this.currentUser.id + ' . ' + this.currentUser.firstName + ' ' + this.currentUser.lastName,
      projectId: '',
      subprojectId: '',
      projectManagerId: '',
      description: '',
      startDate: '',
      endDate: '',
      totalHours: '',
      status: '',
      system: '',
      subSystem: '',
      subSubSystem: '',
      leadEngineerName: '',
    });
  }

  updateTimesheeets() {
    let updatedetails = {
      id: '',
      assignedTo: parseInt(this.timesheetForm.get('assignedTo').value.toString().split(" . ")[0]),
      assignedToName: this.timesheetForm.get('assignedTo').value.split(' . ')[1],
      projectId: parseInt(this.timesheetForm.get('projectId').value.toString().split(" . ")[0]),
      projectName: this.timesheetForm.get('projectId').value.toString().split(" . ")[1],
      subprojectId: parseInt(this.timesheetForm.get('subprojectId').value.toString().split(" . ")[0]),
      subprojectName: this.timesheetForm.get('subprojectId').value.toString().split(" . ")[1],
      projectManagerId: parseInt(this.timesheetForm.get('projectManagerId').value.split(' . ')[0]),
      projectManagerName: this.timesheetForm.get('projectManagerId').value.split(' . ')[1],
      description: this.timesheetForm.get('description').value,
      startDate: this.timesheetForm.get('startDate').value,
      endDate: this.timesheetForm.get('endDate').value,
      totalHours: this.timesheetForm.get('totalHours').value,
      status: this.timesheetForm.get('status').value,
      projectTaskName: this.timesheetForm.get('projectTaskName').value.toString().split(" . ")[1],
      projectTaskId: parseInt(this.timesheetForm.get('projectTaskName').value.toString().split(" . ")[0]),
      leadEngineerName: this.timesheetForm.get('leadEngineerName').value.toString().split(" . ")[1],
      leadEngineerId: parseInt(this.timesheetForm.get('leadEngineerName').value.toString().split(" . ")[0])
    };
    this.timesheetService.updateTimesheet(updatedetails, parseInt(this.selectedCheckboxData[0].id)).subscribe((res) => {
        this.getTimesheet();
        // this.selectedCheckboxData = [];
        $('#closeModalTimeSheet').trigger('click');
      });
  }

  deleteOpenModal() {
    if(this.isDisableEdit){
      this.openSnackBar('You cannot delete old Timesheet Report please contact Your Manager');
      this.isDisableEdit = false;
      this.selectedCheckboxData = [];
    }
    else{
      this.deleteConfirmationMsg = 'Are you sure you want to delete';
      for (let i in this.selectedCheckboxData) {
        if (+i === 0)
          this.deleteConfirmationMsg += ` ${this.selectedCheckboxData[i].projectName}`;
        else if (this.selectedCheckboxData.length === +i + 1)
          this.deleteConfirmationMsg += ` & ${this.selectedCheckboxData[i].projectName}`;
        else
          this.deleteConfirmationMsg += ` , ${this.selectedCheckboxData[i].projectName}`;
      }
    }
  }

  deleteTimesheetList() {
    this.timesheetService.deleteTimesheet(this.selectedCheckboxData[0].id).subscribe(() => {
      this.getTimesheet();
      this.selectedCheckboxData = [];
      $('#closeModalDeleteTimesheet').trigger('click');
    });
  }

  // getter for easy access to form fields
  get f() {
    return this.timesheetForm.controls;
  }

  getUserList() {
    this.userDropdownList = [];
    this.allocationService.getUserDetails().subscribe((response) => {
      this.getUserName = response;
      for (let item of response) {
        this.userDropdownList.push(
          `${item.id} . ${item.firstName} ${item.lastName}`
        );
      }
    });
  }

  getProjectDetails(){
    this.projectDropdownList = [];

    if(this.isAdministrator){
      this.projectService.getProjects().subscribe((response: any) => {
        this.getProjectDetailsList = response;
        for (let item of response) {
          this.projectDropdownList.push(`${item.id} . ${item.projectName}`);
        }
      });
    } else{
      let ignoreArchived : boolean = true;
      this.projectService.getProjectByUserId(this.currentUser.id, ignoreArchived).subscribe((response: any) => {
        this.getProjectDetailsList = response;
        for (let item of response) {
          this.projectDropdownList.push(`${item.id} . ${item.projectName}`);
        }
      });
    }
  }

  getSubProjectList(projectId){
    this.subProjectDropdownList = [];
    
    if(this.isAdministrator){
      this.projectService.getSubProjects(projectId).subscribe((response: any) => {
        for (let item of response) {
          this.subProjectDropdownList.push(`${item.id} . ${item.projectName}`);
        }
      });
    } else{
      let ignoreArchived : boolean = true;
      this.projectService.getSubProjectByUserId(this.currentUser.id, projectId, ignoreArchived).subscribe((response: any) => {
        for (let item of response) {
          this.subProjectDropdownList.push(`${item.id} . ${item.projectName}`);
        }
      });
    }
  }

  getProjectID(par) {
    if (this.getProjectDetailsList != null) {
      var result = this.getProjectDetailsList.filter(function (chain) {
        return chain.id === par;
      });
      var value = result[0].id + ' . ' + result[0].project;
    }
    return value;
  }

  getUserID(par) {
    if (this.getUserName != null) {
      var result = this.getUserName.filter(function (chain) {
        return chain.id === par;
      });
      if (result[0] && (result[0].firstName !== null || result[0].lastName !== null)) {
        var userName =result[0].id + ' . ' + result[0].firstName + ' ' + result[0].lastName;
      }
    }
    return userName;
  }


  timesheetGraph(){
    this.router.navigate([this.tenantId + '/dashboard/hr/timesheet/timesheet-graph']);
  }
  timesheetReport(){
    this.router.navigate([this.tenantId + '/dashboard/hr/timesheet/timesheet-report']);
  }

  gettimesheetSummary(){
    let startDate = this.timesheetForm.get('startDate').value;
    startDate = convertYYYYMMDDtoDDMMYYYY(startDate);
    let endDate = this.timesheetForm.get('endDate').value;
    endDate = convertYYYYMMDDtoDDMMYYYY(endDate);
    this.timesheetService.getTimesheetDateRange(startDate, endDate).subscribe((response: any) => {
      if(response.result && response.result.length > 0) {
        this.rowData = response.result.map(x => ({...x }));
      } else {
        this.rowData = [];
      }
    });
  }

  fetchProjectDetails(projectId){
    this.timesheetForm.patchValue({
      subProjectId : '',
      projectManagerId: '',
      leadEngineerName: '',
      projectTaskName: '',
      system: '',
      subSystem: '',
      subSubSystem: '',
      startDate: '',
      endDate: '',
      totalHours: '',
      status: '',
      description: ''
    })
    let selectedProject = projectId.target.value;
    this.selectedProjectId = selectedProject.split(' . ')[0];
    this.getSubProjectList(this.selectedProjectId);
    this.getProjectTaskByProjectId();
    //Get ProjectManager Details
    this.projectService.getProject(this.selectedProjectId).subscribe((response: any) => {
      if (response) {
       this.timesheetForm.value.projectManagerId = response.projectManagerId + " . " + response.projectManager;
       this.timesheetForm.patchValue(this.timesheetForm.value);
      }
    });

    //Get Project Lead Engineer
    this.projectResourcesService.getAllProjectResourcesByProjectId(this.selectedProjectId).subscribe((response: any) => {
      for(let item of response.result){
        if(item.projectRole === 'Lead'){
          this.timesheetForm.value.leadEngineerName = item.userId + " . " + item.userName;
          this.timesheetForm.patchValue(this.timesheetForm.value);
        }
      }
    });
  }

  getProjectTaskByProjectId(){
    this.projectTaskDropdown = [];
    if(this.timesheetForm.get('assignedTo').value){
      let id = this.timesheetForm.get('assignedTo').value.split(' . ')[0];
      this.taskService.getProjectTaskByStatus(id, this.selectedProjectId).subscribe((response: any) => {
        if(response){
          for (let item of response.result) {
            this.projectTaskDropdown.push(`${item.id} . ${item.name}`);
          }
        }
      });
    }
    else{
      this.taskService.getProjectTaskByStatus(this.currentUser.id, this.selectedProjectId).subscribe((response: any) => {
        if(response){
          for (let item of response.result) {
            this.projectTaskDropdown.push(`${item.id} . ${item.name}`);
          }
        }
      });
    }
  }

  getSubProjectTaskByProjectId(subprojectId){
    this.projectTaskDropdown = [];
    let selectedSubProjectId = subprojectId.target.value.split(' . ')[0];
    if(this.timesheetForm.get('assignedTo').value){
      let id = this.timesheetForm.get('assignedTo').value.split(' . ')[0];
      if(selectedSubProjectId){
        this.taskService.getProjectTaskByStatus(id, selectedSubProjectId).subscribe((response: any) => {
          if(response){
            for (let item of response.result) {
              this.projectTaskDropdown.push(`${item.id} . ${item.name}`);
            }
          }
        });
      }
      else{
        this.taskService.getProjectTaskByStatus(id, this.selectedProjectId).subscribe((response: any) => {
          if(response){
            for (let item of response.result) {
              this.projectTaskDropdown.push(`${item.id} . ${item.name}`);
            }
          }
        });
      } 
    }
    else{
      if(selectedSubProjectId){
        this.taskService.getProjectTaskByStatus(this.currentUser.id, selectedSubProjectId).subscribe((response: any) => {
          if(response){
            for (let item of response.result) {
              this.projectTaskDropdown.push(`${item.id} . ${item.name}`);
            }
          }
        });
      }
      else{
        this.taskService.getProjectTaskByStatus(this.currentUser.id, this.selectedProjectId).subscribe((response: any) => {
          if(response){
            for (let item of response.result) {
              this.projectTaskDropdown.push(`${item.id} . ${item.name}`);
            }
          }
        });
      }
    }
  }

  projectTask(){
    this.router.navigate([this.tenantId + '/dashboard/hr/timesheet/project-task']);
  }

  getTaskDetails(task){
    let taskId: any;
    if(this.modeAction === 2){
      taskId = task;
    }
    else{
      taskId = parseInt(this.timesheetForm.get('projectTaskName').value.toString().split(" . ")[0]);
    }

    this.taskService.getProjectTasksById(taskId).subscribe((response: any) => {
      this.timesheetForm.value.system = response.system;
      this.timesheetForm.value.subSystem = response.subSystem;
      this.timesheetForm.value.subSubSystem = response.subSubSystem;
      this.timesheetForm.value.startDate = response.startDate;
      this.timesheetForm.value.endDate = response.endDate;
      this.timesheetForm.value.totalHours = response.estimatedTimeInHours;
      this.timesheetForm.patchValue(this.timesheetForm.value);
    })
  }
  
}
